import { useState, useEffect } from "react";
import emailjs from "emailjs-com";
import { getFirestore, collection, getDocs, query, orderBy, limit } from "firebase/firestore";
import Button from "./UI/Button";
import CommonToast from "./util/Toasts";

import "./Enquiry.scss";

const EnquiryForm = () => {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastVariant, setToastVariant] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    to_name: "skyrites",
    from_name: "",
    reply_to: "",
    from_subject: "",
    message: ""
  });
  const [emailConfig, setEmailConfig] = useState(null);

  // Fetch EmailJS configuration from Firestore
  useEffect(() => {
    const fetchEmailConfig = async () => {
      const db = getFirestore();
      const emailConfigCollection = collection(db, "emailConfig");
      const q = query(emailConfigCollection, orderBy("timestamp", "desc"), limit(1));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const latestDoc = querySnapshot.docs[0];
        setEmailConfig(latestDoc.data());
      } else {
        console.log("No email configuration found!");
      }
    };

    fetchEmailConfig();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { from_name, reply_to, from_subject, message } = formData;

    if (!from_name || !reply_to || !from_subject || !message) {
      setToastVariant("danger");
      setToastMessage("Please fill in all fields.");
      setShowToast(true);

      return;
    }

    setIsSubmitting(true);

    if (emailConfig) {
      emailjs
        .send(emailConfig.serviceId, emailConfig.templateId, formData, emailConfig.userId)
        .then(
          () => {
            setToastMessage("Email sent successfully!");
            setToastVariant("success");
            setShowToast(true);
            setIsSubmitting(false);
            setFormData({
              to_name: "skyrites",
              from_name: "",
              reply_to: "",
              from_subject: "",
              message: ""
            });
          },
          () => {
            setToastMessage("Failed to send email.");
            setToastVariant("danger");
            setShowToast(true);
            setIsSubmitting(false);
          }
        );
    } else {
      setToastMessage("Email configuration not loaded.");
      setToastVariant("danger");
      setShowToast(true);
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <form className="form full-width" onSubmit={handleSubmit}>
        <div className="messages"></div>
        <div className="controls container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <input
                  id="form_name"
                  type="text"
                  name="from_name"
                  placeholder="Name *"
                  value={formData.from_name}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <input
                  id="form_email"
                  type="email"
                  name="reply_to"
                  placeholder="Email *"
                  value={formData.reply_to}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <input
                  id="from_subject"
                  type="text"
                  name="from_subject"
                  placeholder="Subject *"
                  value={formData.from_subject}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <textarea
                  id="form_message"
                  name="message"
                  placeholder="Your Message *"
                  rows="4"
                  value={formData.message}
                  onChange={handleChange}
                ></textarea>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <Button
                  buttonType="button"
                  type="submit"
                  dataFront={isSubmitting ? "Sending..." : "Send Message"}
                  ariaLabel="Submit your query"
                  disabled={isSubmitting}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
      <CommonToast
        show={showToast}
        onClose={() => setShowToast(false)}
        message={toastMessage}
        toastVariant={toastVariant}
      />
    </>
  );
};

export default EnquiryForm;
