import { useEffect, useRef } from "react";

export default function SkillProgress({ skillName, dataValue }) {
  const progressBarRef = useRef(null);

  useEffect(() => {
    const handleScroll = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.firstChild.style.width = entry.target.getAttribute("data-value");
        }
      });
    };

    const observer = new IntersectionObserver(handleScroll, {
      threshold: 0.1
    });

    const currentProgressBar = progressBarRef.current;

    if (currentProgressBar) {
      observer.observe(currentProgressBar);
    }

    // Cleanup the observer on component unmount
    return () => {
      if (currentProgressBar) {
        observer.unobserve(currentProgressBar);
      }
    };
  }, []);

  return (
    <div className="skill">
      <h6>
        {skillName} <span>{dataValue}%</span>
      </h6>
      <div className="skills-progress" ref={progressBarRef} data-value={`${dataValue}%`}>
        <span style={{ width: "0%" }}></span>
      </div>
    </div>
  );
}
