import React, { useEffect, useState } from "react";
import { collection, getDocs, doc, deleteDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { Button, Card, Col, ListGroup, Row } from "react-bootstrap";
import AdminHeading from "../../components/util/AdminHeading";

const ServiceList = () => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchServices = async () => {
      setLoading(true);
      try {
        const querySnapshot = await getDocs(collection(db, "services"));
        const servicesData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data()
        }));
        setServices(servicesData);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchServices();
  }, []);

  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(db, "services", id));
      setServices((prevServices) => prevServices.filter((service) => service.id !== id));
    } catch (err) {
      setError("Failed to delete service");
      console.error("Error deleting document: ", err);
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div>
      <h2>Service List</h2>
      <ListGroup>
        {services.map((service) => (
          <ListGroup.Item key={service.id}>
            <h5>{service.serviceTitle}</h5>
            <p>{service.serviceContent}</p>
            {service.sections && (
              <Row>
                {service.sections.map((section, index) => (
                  <Col md={4} key={index}>
                    <Card className="item mb-md50">
                      <Card.Body>
                        <figure className="w-120 border rounded-pill d-flex justify-content-center align-items-center mx-auto">
                          {section.serviceImg ? (
                            <img
                              src={section.serviceImg}
                              alt={section.title}
                              style={{ width: "100px" }}
                            />
                          ) : (
                            <span className={`icon fs-2 ${section.iconName || ""}`}></span>
                          )}
                        </figure>

                        <AdminHeading level={6} title={section.title} />
                        <p dangerouslySetInnerHTML={{ __html: section.content }}></p>
                        <Button variant="danger" onClick={() => handleDelete(service.id)}>
                          Delete Section
                        </Button>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
            )}
            <Button variant="danger" onClick={() => handleDelete(service.id)}>
              Delete Service
            </Button>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </div>
  );
};

export default ServiceList;
