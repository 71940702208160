import { useEffect, useState } from "react";
import { getFirestore, collection, getDocs, addDoc, doc, updateDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import Form from "react-bootstrap/Form";
import Button from "../../../components/UI/Button";
import ImageCropModal from "../../../components/ImageCropModal"; // Assuming the ImageCropModal component is in the same directory
import { v4 as uuidv4 } from "uuid";

const HeroSliderForm = () => {
  const [formData, setFormData] = useState({
    firstTitle: "",
    secondTitle: "",
    thirdTitle: "",
    fourthTitle: ""
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [docId, setDocId] = useState(null); // To store the document ID
  const [imageSrc, setImageSrc] = useState(null);
  const [showCropper, setShowCropper] = useState(false);
  const [tempImageUrl, setTempImageUrl] = useState(""); // Temporary image URL state

  useEffect(() => {
    const fetchData = async () => {
      const db = getFirestore();
      const heroSliderCollection = collection(db, "heroSlider");
      const querySnapshot = await getDocs(heroSliderCollection);

      if (!querySnapshot.empty) {
        const latestDoc = querySnapshot.docs[querySnapshot.docs.length - 1]; // Get the last document
        const data = latestDoc.data();

        setFormData({
          firstTitle: data.firstTitle || "",
          secondTitle: data.secondTitle || "",
          thirdTitle: data.thirdTitle || "",
          fourthTitle: data.fourthTitle || "",
          backgroundImage: data.backgroundImage || ""
        });

        setDocId(latestDoc.id); // Store the document ID for updating later
      } else {
        console.log("No documents found!");
      }
    };

    fetchData();
  }, []);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setTempImageUrl(reader.result); // Set the temporary image URL
        setImageSrc(reader.result);
        setShowCropper(true);
      };
    }
  };

  const handleCropClose = async (croppedImageBlob) => {
    if (croppedImageBlob) {
      const storageRef = ref(getStorage(), `images/${uuidv4()}.webp`); // Use UUID for unique file name
      await uploadBytes(storageRef, croppedImageBlob);
      const downloadURL = await getDownloadURL(storageRef);
      setFormData({ ...formData, backgroundImage: downloadURL }); // Update formData with the new imageUrl
      setTempImageUrl(downloadURL); // Set temporary image URL with cropped image
    }
    setShowCropper(false);
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.backgroundImage) newErrors.imageFile = "Background Image is required.";
    if (!formData.firstTitle) newErrors.firstTitle = "First Title is required.";
    if (!formData.secondTitle) newErrors.secondTitle = "Second Title is required.";
    if (!formData.thirdTitle) newErrors.thirdTitle = "Third Title is required.";
    if (!formData.fourthTitle) newErrors.fourthTitle = "Fourth Title is required.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true);

    try {
      // Generate a UUID for the form submission
      const submissionId = uuidv4();

      // Add form data to Firestore with the submission UUID
      const db = getFirestore();
      const submissionData = {
        ...formData,
        submissionId, // Include the UUID
        backgroundImage: formData.backgroundImage
      };

      if (docId) {
        // Update existing document
        await updateDoc(doc(db, "heroSlider", docId), submissionData);
      } else {
        // Create a new document if it doesn't exist
        const docRef = await addDoc(collection(db, "heroSlider"), submissionData);
        setDocId(docRef.id); // Store the document ID for future updates
      }

      setSuccessMessage("Data submitted successfully!");

      // Clear form data
      setFormData({
        firstTitle: "",
        secondTitle: "",
        thirdTitle: "",
        fourthTitle: ""
      });
      setTempImageUrl(""); // Clear the temporary image URL

      // Clear success message after a delay
      setTimeout(() => {
        setSuccessMessage("");
      }, 3000);
    } catch (error) {
      alert("Error submitting data: " + error.message);
    }

    setIsSubmitting(false);
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="backgroundSliderFile" className="mb-3">
          <Form.Label>Background Image</Form.Label>
          <Form.Control type="file" onChange={handleFileChange} isInvalid={!!errors.imageFile} />
          <Form.Control.Feedback type="invalid">{errors.imageFile}</Form.Control.Feedback>
        </Form.Group>

        {showCropper && (
          <ImageCropModal
            show={showCropper}
            handleClose={handleCropClose}
            imageSrc={imageSrc}
            aspect={32 / 11} // Set the desired aspect ratio here
          />
        )}

        {tempImageUrl && !formData.backgroundImage && (
          <div style={{ width: "120px" }}>
            <img src={tempImageUrl} alt="Temporary" className="img-thumbnail" />
          </div>
        )}
        {formData.backgroundImage && !tempImageUrl && (
          <div style={{ width: "150px" }}>
            <img src={formData.backgroundImage} alt="Uploaded" className="img-thumbnail" />
          </div>
        )}

        <Form.Group className="mb-3" controlId="firstTitle">
          <Form.Label>First Title</Form.Label>
          <Form.Control
            type="text"
            placeholder="First Title"
            value={formData.firstTitle}
            onChange={handleChange}
            isInvalid={!!errors.firstTitle}
          />
          <Form.Control.Feedback type="invalid">{errors.firstTitle}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3" controlId="secondTitle">
          <Form.Label>Second Title</Form.Label>
          <Form.Control
            type="text"
            placeholder="Second Title"
            value={formData.secondTitle}
            onChange={handleChange}
            isInvalid={!!errors.secondTitle}
          />
          <Form.Control.Feedback type="invalid">{errors.secondTitle}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3" controlId="thirdTitle">
          <Form.Label>Third Title</Form.Label>
          <Form.Control
            type="text"
            placeholder="Third Title"
            value={formData.thirdTitle}
            onChange={handleChange}
            isInvalid={!!errors.thirdTitle}
          />
          <Form.Control.Feedback type="invalid">{errors.thirdTitle}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3" controlId="fourthTitle">
          <Form.Label>Fourth Title</Form.Label>
          <Form.Control
            type="text"
            placeholder="Fourth Title"
            value={formData.fourthTitle}
            onChange={handleChange}
            isInvalid={!!errors.fourthTitle}
          />
          <Form.Control.Feedback type="invalid">{errors.fourthTitle}</Form.Control.Feedback>
        </Form.Group>

        <Button
          dataFront="Submit"
          ariaLabel="Submit"
          buttonType="button"
          type="submit"
          disabled={isSubmitting}
        />
      </Form>
      {successMessage && <div className="alert alert-success mt-3">{successMessage}</div>}
    </div>
  );
};

export default HeroSliderForm;
