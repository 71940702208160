import AdminHeading from "../../components/util/AdminHeading";

const AdminSetting = () => {
  return (
    <>
      <AdminHeading title="AdminSetting" /> AdminSetting.jsx
    </>
  );
};
export default AdminSetting;
