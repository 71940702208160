import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { auth } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";
import Header from "../components/Header/Header";

function RootLayout() {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });

    return () => unsubscribe();
  }, []);
  // Initialize theme from localStorage or default to "dark-theme"
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "dark-theme");

  useEffect(() => {
    document.body.className = theme; // Set the body class to the current theme
    localStorage.setItem("theme", theme); // Store the current theme in localStorage
  }, [theme]);

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "light-theme" ? "dark-theme" : "light-theme"));
  };
  return (
    <>
      <Header currentUser={currentUser} />
      <div className="theme-switcher">
        <input
          id="switcher"
          type="checkbox"
          checked={theme === "light-theme"}
          onChange={toggleTheme}
        />
        <label htmlFor="switcher">Switch</label>
      </div>
      <main className="content">
        <Outlet />
      </main>
    </>
  );
}
export default RootLayout;
