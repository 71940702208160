// import { useState, useEffect } from "react";
// import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
// import Lightbox from "yet-another-react-lightbox";
// import "yet-another-react-lightbox/styles.css";

import Portfolio from "../../components/Portfolio";

// import { collection, getDocs } from "firebase/firestore";
// import { db } from "../../firebase";

// // import { portfolioItems } from "../../data/portfolioItemsData";

// import PortfolioItem from "../../components/util/PortfolioItem";
// import BackHeader from "../../components/Header/BackHeader";

// const FilterButtons = ({ filter, setFilter, portfolioItems }) => {
//   const categoriesFromItems = Array.from(
//     new Set(
//       portfolioItems.flatMap((item) => item.categories.map((category) => category.toLowerCase()))
//     )
//   );

//   // Add 'All' to the beginning of the array
//   const filters = ["all", ...categoriesFromItems];

//   // console.log(filters);
//   const formatLabel = (label) => {
//     return label.replace(/-/g, " ").replace(/\b\w/g, (char) => char.toUpperCase());
//   };

//   return (
//     <div className="filter-buttons w-100 filtering mb-5">
//       <div className="d-flex flex-md-wrap w-100">
//         {filters.map((f) => (
//           <button
//             key={f}
//             className={`filter-button ${filter === f ? "active" : ""}`}
//             onClick={() => setFilter(f)}
//           >
//             {formatLabel(f)}
//           </button>
//         ))}
//       </div>
//     </div>
//   );
// };

// const PortfolioPage = ({ itemsShow }) => {
//   // State
//   const [index, setIndex] = useState(-1);
//   const [filter, setFilter] = useState("all");
//   const [portfolioItems, setPortfolioItems] = useState([]);

//   useEffect(() => {
//     const fetchPortfolioItems = async () => {
//       const querySnapshot = await getDocs(collection(db, "portfolioItems"));
//       const items = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
//       setPortfolioItems(items);
//     };

//     fetchPortfolioItems();
//   }, []);

//   const filteredItems =
//     filter === "all"
//       ? portfolioItems
//       : portfolioItems.filter((item) =>
//           item.categories.map((category) => category.toLowerCase()).includes(filter)
//         );
//   // Limit to the first 4 items
//   // const limitedItems = filteredItems.slice(0, 10);
//   return (
//     <>
//       <section className="portfolio section-padding">
//         <div className="container">
//           <div className="row">
//             <div className="col-12">
//               <BackHeader />
//               <div className="section-head full-width">
//                 <h4 className="title">My Portfolio</h4>
//               </div>
//             </div>
//           </div>
//           <FilterButtons filter={filter} setFilter={setFilter} portfolioItems={portfolioItems} />
//           <ResponsiveMasonry columnsCountBreakPoints={{ 320: 1, 340: 2, 600: 3, 900: 4 }}>
//             <Masonry>
//               {filteredItems.map((item, index) => (
//                 <PortfolioItem
//                   key={index}
//                   idx={index}
//                   src={item.src}
//                   alt={item.alt}
//                   title={item.title}
//                   link={item.link}
//                   categories={item.categories}
//                   url={item.url}
//                   clickEvent={(idx) => setIndex(idx)}
//                 />
//               ))}
//             </Masonry>
//           </ResponsiveMasonry>
//         </div>
//       </section>
//       <Lightbox index={index} open={index >= 0} close={() => setIndex(-1)} slides={filteredItems} />
//     </>
//   );
// };
const PortfolioPage = () => {
  return <Portfolio backHeader />;
};

export default PortfolioPage;
