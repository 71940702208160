// ImageCropModal.jsx

import { useState, useCallback } from "react";
import { Modal, Button } from "react-bootstrap";
import Cropper from "react-easy-crop";
import getCroppedImg from "./util/cropImage"; // Utility function to crop the image

const ImageCropModal = ({
  show,
  handleClose,
  imageSrc,
  onCropComplete,
  aspect = 16 / 9,
  width
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropCompleteHandler = useCallback(
    (croppedArea, croppedAreaPixels) => {
      setCroppedAreaPixels(croppedAreaPixels);
      if (onCropComplete) {
        onCropComplete(croppedArea, croppedAreaPixels); // Ensure onCropComplete is called
      }
    },
    [onCropComplete]
  );

  const handleCrop = async () => {
    try {
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
      handleClose(croppedImage); // Pass blob to handleClose
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Modal show={show} onHide={() => handleClose(null)} centered>
      <Modal.Header closeButton>
        <Modal.Title>Crop Image</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="crop-container">
          <Cropper
            image={imageSrc}
            crop={crop}
            zoom={zoom}
            aspect={aspect}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={onCropCompleteHandler} // Ensure this is set correctly
            width={width}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => handleClose(null)}>
          Close
        </Button>
        <Button variant="primary" onClick={handleCrop}>
          Crop
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ImageCropModal;
