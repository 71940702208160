// Import Swiper React components
import { useState, useEffect } from "react";
import { getFirestore, collection, getDocs, query, orderBy, limit } from "firebase/firestore";

// image
import Button from "./UI/Button";
import { Swiper, SwiperSlide } from "swiper/react"; // Import Swiper styles
import { Virtual, Pagination, Autoplay } from "swiper/modules";

// css
import "swiper/css";
import "swiper/css/virtual";
import "swiper/css/pagination";
import Loader from "./Loader";

export default function Testimonials() {
  const [testimonialsData, setTestimonialsData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const db = getFirestore();
      const TestimonialsCollection = collection(db, "Testimonials");
      const q = query(TestimonialsCollection, orderBy("timestamp", "desc"), limit(1));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const latestDoc = querySnapshot.docs[0];
        const data = latestDoc.data();
        setTestimonialsData(data);
      } else {
        console.log("No documents found!");
      }
    };

    fetchData();
  }, []);

  if (!testimonialsData) {
    return <Loader />;
  }
  return (
    <section
      className="testimonials section-padding bg-img"
      data-overlay-dark="8"
      style={{ backgroundImage: `url(${testimonialsData.imageUrl})` }}
    >
      <div className="container">
        <div className="row">
          {/* <!-- Section-Head --> */}
          <div className="section-head full-width mb-50">
            <span className={`icon ${testimonialsData.iconName}`}></span>
            <br />
            <h4 className="title">{testimonialsData.Title}</h4>
          </div>

          <div className="offset-md-1 col-md-10">
            <Swiper
              modules={[Virtual, Pagination, Autoplay]}
              centeredSlides={true}
              autoplay={{
                delay: 8000,
                disableOnInteraction: false
              }}
              pagination={{
                clickable: true
              }}
              spaceBetween={50}
              slidesPerView={1}
              virtual
            >
              {testimonialsData.sections.map((slideContent, index) => (
                <SwiperSlide key={slideContent.id} virtualIndex={index}>
                  <div className="citem text-center">
                    <p>{slideContent.clientDescription}</p>
                    <div className="d-flex justify-content-center align-items-center">
                      <h5>{slideContent.clientName}</h5>
                      <h6 className="ml-1">{slideContent.clientLocation}</h6>
                    </div>
                    {slideContent.url && (
                      <div className="mt-3">
                        <Button
                          buttonType="link"
                          dataFront="Read more"
                          buttonLink={slideContent.url}
                          target="_blank"
                          ariaLabel="Hire me on Freelancer"
                        />
                      </div>
                    )}
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
}
