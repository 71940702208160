export default function Footer() {
    let year = new Date().getFullYear();
    return (
        <footer className="footer">
            <p>
                Copyright &copy; All Rights Reserved <span id="dnt">{year}</span>.
            </p>
        </footer>
    );
}
