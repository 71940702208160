// PortfolioForm.jsx
import { useState, useEffect, useRef } from "react";
import { Form, Button, Container, Row, Col, Card, CardHeader, CardFooter } from "react-bootstrap";
import {
  collection,
  addDoc,
  getDocs,
  doc,
  deleteDoc,
  updateDoc,
  serverTimestamp
} from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import { db, storage } from "../../../firebase";
import AdminHeading from "../../../components/util/AdminHeading";

const PortfolioForm = () => {
  const [portfolio, setPortfolio] = useState({
    id: uuidv4(),
    src: "",
    alt: "",
    categories: [],
    title: "",
    url: "",
    createdAt: serverTimestamp()
  });
  const [portfolioItems, setPortfolioItems] = useState([]);
  const [editingId, setEditingId] = useState(null);
  const [imageSrc, setImageSrc] = useState("");
  const inputFileRef = useRef(null);

  const fetchPortfolioItems = async () => {
    const querySnapshot = await getDocs(collection(db, "portfolioItems"));
    const items = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    setPortfolioItems(items);
  };

  useEffect(() => {
    fetchPortfolioItems();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPortfolio((prevState) => ({
      ...prevState,
      [name]: name === "categories" ? value.split(",") : value
    }));
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const storageRef = ref(storage, `portfolioImages/${uuidv4()}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
        },
        (error) => {
          console.error("Upload error:", error);
        },
        async () => {
          // Handle successful uploads on complete
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          setImageSrc(downloadURL);
          setPortfolio((prevState) => ({
            ...prevState,
            src: downloadURL
          }));
        }
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newPortfolioItem = { ...portfolio, id: uuidv4(), createdAt: serverTimestamp() };
    if (editingId) {
      await updateDoc(doc(db, "portfolioItems", editingId), {
        ...portfolio,
        updatedAt: serverTimestamp()
      });
      setEditingId(null);
    } else {
      await addDoc(collection(db, "portfolioItems"), newPortfolioItem);
    }
    setPortfolio({
      id: uuidv4(),
      src: "",

      categories: [],
      title: "",

      url: "",
      createdAt: serverTimestamp()
    });
    fetchPortfolioItems();
  };

  const handleEdit = (item) => {
    setPortfolio(item);
    setEditingId(item.id);
  };

  const handleDelete = async (id) => {
    await deleteDoc(doc(db, "portfolioItems", id));
    fetchPortfolioItems();
  };

  return (
    <Container>
      <Row className="mb-4">
        <Col>
          <Card>
            <CardHeader>
              <h4 className="text-black m-0">Add portfolio item</h4>
            </CardHeader>
            <Card.Body>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="myPhoto" className="mb-3">
                  <Form.Label>My Photo</Form.Label>
                  <Form.Control type="file" onChange={handleFileChange} ref={inputFileRef} />
                  <Form.Control.Feedback type="invalid">error</Form.Control.Feedback>
                </Form.Group>
                {imageSrc && (
                  <div style={{ width: "100px", height: "150px", overflow: "hidden" }}>
                    <img
                      src={imageSrc}
                      alt="Uploaded"
                      className="img-thumbnail object-fit-contain h-100"
                    />
                  </div>
                )}
                <Row>
                  <Form.Group className="col col-lg-6 mb-4">
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      type="text"
                      name="title"
                      value={portfolio.title}
                      onChange={handleChange}
                    />
                  </Form.Group>

                  <Form.Group className="col col-lg-6 mb-4">
                    <Form.Label>Categories (comma-separated)</Form.Label>
                    <Form.Control
                      type="text"
                      name="categories"
                      value={portfolio.categories.join(",")}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Row>

                <Form.Group className="mb-4">
                  <Form.Label>URL</Form.Label>
                  <Form.Control
                    type="text"
                    name="url"
                    value={portfolio.url}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Button variant="primary" type="submit">
                  {editingId ? "Update" : "Submit"}
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <AdminHeading level={4} title="Portfolio Items" />
      <Row>
        {portfolioItems.map((item) => (
          <Col key={item.id} sm={12} md={4} lg={3}>
            <Card className="mb-4">
              <Card.Img
                variant="top"
                src={item.src}
                alt={item.title}
                style={{ height: "150px" }}
                className="object-fit-contain border-bottom"
              />
              <Card.Body>
                <Card.Title className="text-truncate">{item.title}</Card.Title>
                <Card.Text className="small">
                  <i class="fas fa-stream"></i> : {item.categories.join(", ")}
                </Card.Text>

                <Card.Text className="small">
                  <i class="fas fa-link"></i> : <a href={item.url}>{item.url}</a>
                </Card.Text>
              </Card.Body>
              <CardFooter className="gap-2 justify-content-between d-flex">
                <Button variant="warning" onClick={() => handleEdit(item)}>
                  <i class="fas fa-edit"></i>
                </Button>
                <Button variant="danger" className="ml-2" onClick={() => handleDelete(item.id)}>
                  <i class="fas fa-trash-alt"></i>
                </Button>
              </CardFooter>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default PortfolioForm;
