import { useState, useEffect } from "react";
import { getFirestore, collection, getDocs, query, orderBy, limit } from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";

const States = () => {
  const [formData, setFormData] = useState({
    id: uuidv4(),
    imageUrl: "",
    sections: [
      {
        id: uuidv4(),
        statesName: "",
        statesValuePercentage: false,
        statesNameExtra: "",
        statesValue: "",
        statesIconName: ""
      }
    ]
  });

  const [imageUrl, setImageUrl] = useState("");
  const [dataFetched, setDataFetched] = useState(false); // New state to track data fetch

  const fetchData = async () => {
    const db = getFirestore();
    const statesCollection = collection(db, "states");
    const q = query(statesCollection, orderBy("timestamp", "desc"), limit(1));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const latestDoc = querySnapshot.docs[0];
      const data = latestDoc.data();
      setFormData({
        ...data,
        id: uuidv4(),
        sections: data.sections.map((section) => ({ ...section, id: uuidv4() }))
      });
      setImageUrl(data.imageUrl);
      setDataFetched(true); // Set data fetched to true
    } else {
      console.log("No documents found!");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (dataFetched) {
      const counters = document.querySelectorAll(".count");
      const speed = 200;

      const countUp = (counter) => {
        const updateCount = () => {
          const target = +counter.getAttribute("data-target");
          const count = +counter.innerText;

          const inc = target / speed;

          if (count < target) {
            counter.innerText = Math.ceil(count + inc);
            setTimeout(updateCount, 1);
          } else {
            counter.innerText = target;
          }
        };

        updateCount();
      };

      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              const counter = entry.target;
              countUp(counter);
              observer.unobserve(counter);
            }
          });
        },
        { threshold: 0.1 }
      );

      counters.forEach((counter) => {
        observer.observe(counter);
      });

      // Cleanup
      return () => {
        counters.forEach((counter) => {
          observer.unobserve(counter);
        });
      };
    }
  }, [dataFetched]);

  return (
    <section
      className="numbers bg-img"
      data-stellar-background-ratio="0.5"
      data-overlay-dark="8"
      style={{ backgroundImage: `url(${imageUrl})` }}
    >
      <div className="container">
        <div className="row">
          {formData.sections.map((section) => (
            <div className="col-12 col-sm-6 col-md-4" key={section.id}>
              <div className="item mb-md50">
                <span className={`icon ${section.statesIconName}`}></span>
                <h2>
                  <span className="count" data-target={section.statesValue}>
                    0
                  </span>
                  {section.statesNameExtra && section.statesNameExtra}
                  {section.statesValuePercentage && "%"}{" "}
                </h2>
                <p>{section.statesName}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default States;
