import { NavLink } from "react-router-dom";
import AdminHeading from "../../components/util/AdminHeading";

const Dashboard = () => {
  return (
    <>
      <AdminHeading title="Dashboard" />

      <div className="p-3 mb-5 bg-white">
        <div className="container">
          <div className="row mb-5 text-center">
            <div className="col-6 col-md-6 col-sm-6 col-lg-3 mb-3">
              <div className="card py-4 counter border-0 shadow rounded text-bg-danger bg-gradient">
                <div className="display-between align-items-center px-3 small">
                  <span>
                    <i className="bx bxs-hourglass bx-sm"></i>
                  </span>
                  <span className="small">Todays</span>
                </div>
                <span className="counter-value h3 mb-3">100</span>
                <h6 className="mb-3">Pending Inspection</h6>
                <NavLink to="#" className="stretched-link"></NavLink>
              </div>
            </div>
            <div className="col-6 col-md-6 col-sm-6 col-lg-3 mb-3">
              <div className="card py-4 counter border-0 shadow rounded text-bg-warning bg-gradient">
                <div className="display-between align-items-center px-3 small">
                  <span>
                    <i className="bx bxs-truck bx-sm"></i>
                  </span>
                  <span className="small">Todays</span>
                </div>
                <span className="counter-value h4 mb-3">500</span>
                <h6 className="mb-3">New Inspection</h6>
                <NavLink to="#" className="stretched-link"></NavLink>
              </div>
            </div>
            <div className="col-6 col-md-6 col-sm-6 col-lg-3 mb-3">
              <div className="card py-4 counter border-0 shadow rounded text-bg-primary bg-gradient">
                <div className="display-between align-items-center px-3 small">
                  <span>
                    <i className="bx bx-user-pin bx-sm"></i>
                  </span>
                  <span className="small">Last Month</span>
                </div>
                <span className="counter-value h4 mb-3">600</span>
                <h6 className="mb-3">Total Clients</h6>
                <NavLink to="#" className="stretched-link"></NavLink>
              </div>
            </div>
            <div className="col-6 col-md-6 col-sm-6 col-lg-3 mb-3">
              <div className="card py-4 counter border-0 shadow rounded text-bg-secondary bg-gradient">
                <div className="display-between align-items-center px-3 small">
                  <span>
                    <i className="bx bx-user-check bx-sm"></i>
                  </span>
                  <span className="small">Last Month</span>
                </div>
                <span className="counter-value h4 mb-3">1500</span>
                <h6 className="mb-3">Total Vendors</h6>
                <NavLink to="#" className="stretched-link"></NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Dashboard;
