import Card from "react-bootstrap/Card";

import AdminHeading from "../../components/util/AdminHeading";
import HeroSliderForm from "./forms/HeroSliderForm";
import AboutMeForm from "./forms/AboutMeForm";
import ResumeForm from "./forms/ResumeForm";
import ServiceForm from "./forms/ServiceForm";
import ServiceList from "./ServiceList";
import StatesForm from "./forms/StatesForm";
import TestimonialsForm from "./forms/TestimonialsForm";

const AdminHome = () => {
  return (
    <>
      <AdminHeading title="AdminHome" />
      <div className="row">
        <div className="col-12 mb-5">
          <Card>
            <Card.Header as="h5">Add Testimonials</Card.Header>
            <Card.Body>
              <TestimonialsForm />
            </Card.Body>
          </Card>
        </div>
      </div>
      <div className="row">
        <div className="col-12 mb-5">
          <Card>
            <Card.Header as="h5">Add Stats</Card.Header>
            <Card.Body>
              <StatesForm />
            </Card.Body>
          </Card>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <Card>
            <Card.Header as="h5">Add Services</Card.Header>
            <Card.Body>
              <ServiceList />
              <ServiceForm />
            </Card.Body>
          </Card>
        </div>
      </div>
      <div className="row">
        <div className="col-12 mb-4">
          <Card>
            <Card.Header as="h5">Hero Slider</Card.Header>
            <Card.Body>
              <HeroSliderForm />
            </Card.Body>
          </Card>
        </div>
      </div>
      <div className="row">
        <div className="col-12 mb-4">
          <Card>
            <Card.Header as="h5">About Me</Card.Header>
            <Card.Body>
              <AboutMeForm />
            </Card.Body>
          </Card>
        </div>
      </div>
      <div className="row">
        <div className="col-12 mb-4">
          <Card>
            <Card.Header as="h5">Resume</Card.Header>
            <Card.Body>
              <ResumeForm />
            </Card.Body>
          </Card>
        </div>
      </div>
      <div className="row">
        <div className="col-12">fdfd</div>
      </div>
    </>
  );
};
export default AdminHome;
