import { useState, useEffect } from "react";
import { getFirestore, collection, getDocs, query, orderBy, limit } from "firebase/firestore";

// image
import Button from "./UI/Button";
import Loader from "./Loader";

export default function HeroSection() {
  const [aboutMeData, setAboutMeData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const db = getFirestore();
      const aboutMeCollection = collection(db, "aboutMe");
      const q = query(aboutMeCollection, orderBy("timestamp", "desc"), limit(1));
      const querySnapshot = await getDocs(q);
      // console.log(q);
      if (!querySnapshot.empty) {
        const latestDoc = querySnapshot.docs[0];
        const data = latestDoc.data();
        setAboutMeData(data);
      } else {
        console.log("No documents found!");
      }
    };

    fetchData();
  }, []);

  if (!aboutMeData) {
    return <Loader />;
  }
  return (
    <section className="hero section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <div className="hero-img mb-md50">
              <img src={aboutMeData.imageUrl} alt="Pravin Zade" />
            </div>
          </div>
          <div className="col-lg-7">
            <div className="content">
              <h4 className="title title-left">{aboutMeData.aboutmeTitle}</h4>
              <p>{aboutMeData.description}</p>
              <ul className="ps-0">
                {aboutMeData.sections.map((section, index) => (
                  <li key={index}>
                    <h6>{section.detailTitle}</h6>
                    <p>{section.detailDescription}</p>
                  </li>
                ))}
              </ul>

              <div className="text-center d-flex gap-4 mt-5">
                <Button
                  buttonType="link"
                  dataFront="Hire Me"
                  buttonLink="https://www.upwork.com/freelancers/~01a88b324a044f7f98"
                  target="_blank"
                  ariaLabel="Hire me on upwork"
                />
                <Button
                  buttonType="link"
                  dataFront="Download CV"
                  buttonLink="CV.pdf"
                  target="_blank"
                  ariaLabel="Download my CV"
                  download="CV.pdf"
                  revert
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
