import { useState, useEffect, useRef } from "react";
import {
  getFirestore,
  collection,
  getDocs,
  addDoc,
  serverTimestamp,
  query,
  orderBy,
  limit
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import ImageCropModal from "../../../components/ImageCropModal";

const TestimonialsForm = () => {
  const [formData, setFormData] = useState({
    id: uuidv4(),
    imageUrl: "",
    Title: "",
    iconName: "",
    sections: [{ id: uuidv4(), clientName: "", clientDescription: "", clientLocation: "" }]
  });
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [tempImageUrl, setTempImageUrl] = useState(""); // Temporary image URL state
  const [imageSrc, setImageSrc] = useState(null);
  const [showCropper, setShowCropper] = useState(false);
  const inputFileRef = useRef(null);

  const fetchData = async () => {
    const db = getFirestore();
    const TestimonialsCollection = collection(db, "Testimonials");
    const q = query(TestimonialsCollection, orderBy("timestamp", "desc"), limit(1));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const latestDoc = querySnapshot.docs[0];
      const data = latestDoc.data();
      setFormData({
        ...data,
        id: uuidv4(),
        sections: data.sections.map((section) => ({ ...section, id: uuidv4() }))
      });
      setImageUrl(data.imageUrl); // Set the imageUrl separately
    } else {
      console.log("No documents found!");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleChange = (e, index, field) => {
    const { id, value } = e.target;
    if (typeof index === "number") {
      const sections = [...formData.sections];
      sections[index][field] = value;
      setFormData({ ...formData, sections });
    } else {
      setFormData({ ...formData, [id]: value });
    }
  };

  const handleCropComplete = (croppedArea, croppedAreaPixels) => {
    // Handle the cropped area here if needed
    // console.log(croppedArea, croppedAreaPixels);
  };

  const onFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        console.log("File loaded: ", reader.result); // Log the loaded file
        setTempImageUrl(reader.result); // Set the temporary image URL
        setImageSrc(reader.result);
        setShowCropper(true);
      };
      setImageFile(file);
    }
  };

  const handleCropClose = async (croppedImageBlob) => {
    if (croppedImageBlob) {
      const storageRef = ref(getStorage(), `images/${imageFile.name}`);
      await uploadBytes(storageRef, croppedImageBlob);
      const downloadURL = await getDownloadURL(storageRef);
      setImageUrl(downloadURL);
      setFormData({ ...formData, imageUrl: downloadURL }); // Update formData with the new imageUrl
      setTempImageUrl(downloadURL); // Set temporary image URL with cropped image
    }
    setShowCropper(false);
  };

  const addSection = () => {
    setFormData((prevData) => ({
      id: uuidv4(),
      ...prevData,
      sections: [
        ...prevData.sections,
        { id: uuidv4(), clientName: "", clientDescription: "", clientLocation: "" }
      ]
    }));
  };

  const removeSection = (id) => {
    setFormData((prevData) => ({
      ...prevData,
      sections: prevData.sections.filter((section) => section.id !== id)
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.Title) newErrors.Title = "About Me Title is required.";
    if (!formData.iconName) newErrors.iconName = "icon Name is required.";
    formData.sections.forEach((section, index) => {
      if (!section.clientName) newErrors[`clientName${index}`] = "Detail Title is required.";
      if (!section.clientLocation)
        newErrors[`clientLocation${index}`] = "client Location Title is required.";
      if (!section.clientDescription)
        newErrors[`clientDescription${index}`] = "Detail Description is required.";
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true);

    try {
      await addDoc(collection(getFirestore(), "Testimonials"), {
        ...formData,
        imageUrl: imageUrl || "",
        timestamp: serverTimestamp() // Add the timestamp here
      });
      setSuccessMessage("Data submitted successfully!");
      setFormData({
        id: uuidv4(),
        imageUrl: "",
        Title: "",
        iconName: "",
        sections: [{ id: uuidv4(), clientName: "", clientDescription: "", clientLocation: "" }]
      });
      fetchData(); // Refresh the data after submission

      setTimeout(() => {
        setSuccessMessage("done re baba");
      }, 3000);
    } catch (error) {
      alert("Error submitting data: " + error.message);
    }

    setIsSubmitting(false);
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="myPhoto">
          <Form.Label>Background Image</Form.Label>
          <Form.Control type="file" onChange={onFileChange} ref={inputFileRef} />
          <Form.Control.Feedback type="invalid">error</Form.Control.Feedback>
        </Form.Group>
        {showCropper && (
          <ImageCropModal
            show={showCropper}
            handleClose={handleCropClose}
            imageSrc={imageSrc}
            onCropComplete={handleCropComplete} // Pass the function here
          />
        )}
        {tempImageUrl && !imageUrl && (
          <div className="mb-3" style={{ width: "120px" }}>
            <img src={tempImageUrl} alt="change" className="img-thumbnail" />
            {console.log("Rendering tempImageUrl: ", tempImageUrl)}
          </div>
        )}
        {imageUrl && !tempImageUrl && (
          <div className="mb-3" style={{ width: "150px" }}>
            <img src={imageUrl} alt="Uploaded" className="img-thumbnail" />
          </div>
        )}
        <Row>
          <Form.Group className="mb-3 col col-md-6" controlId="Title">
            <Form.Label>Testimonials Title</Form.Label>
            <Form.Control
              type="text"
              placeholder="Testimonials Title"
              value={formData.Title}
              onChange={handleChange}
              isInvalid={!!errors.Title}
            />
            <Form.Control.Feedback type="invalid">{errors.Title}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3 col col-md-6" controlId="iconName">
            <Form.Label>Icon Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="icon Name"
              value={formData.iconName}
              onChange={handleChange}
              isInvalid={!!errors.iconName}
            />
            <Form.Control.Feedback type="invalid">{errors.iconName}</Form.Control.Feedback>
          </Form.Group>
        </Row>
        <hr className="my-5" />
        {formData.sections.map((section, index) => (
          <Card border="success" bg="light" className="mb-4">
            <Card.Body>
              <Row className="align-items-end" key={section.id}>
                <Col className="flex-grow-1">
                  <Row>
                    <Col className="col-auto flex-grow-1">
                      <Form.Group className="mb-3" controlId={`clientName${index}`}>
                        <Form.Label>Client Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Client Name"
                          value={section.clientName}
                          onChange={(e) => handleChange(e, index, "clientName")}
                          isInvalid={!!errors[`clientName${index}`]}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors[`clientName${index}`]}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col className="col-auto flex-grow-1">
                      <Form.Group className="mb-3" controlId={`clientLocation${index}`}>
                        <Form.Label>Client Location</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Client Location"
                          value={section.clientLocation}
                          onChange={(e) => handleChange(e, index, "clientLocation")}
                          isInvalid={!!errors[`clientLocation${index}`]}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors[`clientLocation${index}`]}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col className="col-12 flex-grow-1">
                      <Form.Group controlId={`clientDescription${index}`}>
                        <Form.Label>Client Feedback</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          placeholder="Client Feedback"
                          value={section.clientDescription}
                          onChange={(e) => handleChange(e, index, "clientDescription")}
                          isInvalid={!!errors[`clientDescription${index}`]}
                        />

                        <Form.Control.Feedback type="invalid">
                          {errors[`clientDescription${index}`]}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Col className="col-auto gap-2 d-flex justify-content-between">
                  <Button type="button" size="sm" onClick={addSection}>
                    <i className="fas fa-plus"></i>
                  </Button>
                  {formData.sections.length > 1 && (
                    <Button
                      type="button"
                      variant="danger"
                      size="sm"
                      onClick={() => removeSection(section.id)}
                    >
                      <i className="fas fa-minus"></i>
                    </Button>
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        ))}

        <Button type="submit" disabled={isSubmitting}>
          Submit
        </Button>
      </Form>
      {successMessage && <div className="alert alert-success mt-3">{successMessage}</div>}
    </div>
  );
};

export default TestimonialsForm;
