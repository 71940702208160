import { useState, useEffect, useRef } from "react";
import {
  getFirestore,
  collection,
  getDocs,
  addDoc,
  serverTimestamp,
  query,
  orderBy,
  limit
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { Button, Form } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import ImageCropModal from "../../../components/ImageCropModal";

const AboutMeForm = () => {
  const [formData, setFormData] = useState({
    id: uuidv4(),
    imageUrl: "",
    aboutmeTitle: "",
    description: "",
    sections: [{ id: uuidv4(), detailTitle: "", detailDescription: "" }]
  });
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [tempImageUrl, setTempImageUrl] = useState(""); // Temporary image URL state
  const [imageSrc, setImageSrc] = useState(null);
  const [showCropper, setShowCropper] = useState(false);
  const inputFileRef = useRef(null);

  const fetchData = async () => {
    const db = getFirestore();
    const aboutMeCollection = collection(db, "aboutMe");
    const q = query(aboutMeCollection, orderBy("timestamp", "desc"), limit(1));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const latestDoc = querySnapshot.docs[0];
      const data = latestDoc.data();
      setFormData({
        ...data,
        id: uuidv4(),
        sections: data.sections.map((section) => ({ ...section, id: uuidv4() }))
      });
      setImageUrl(data.imageUrl); // Set the imageUrl separately
    } else {
      console.log("No documents found!");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleChange = (e, index, field) => {
    const { id, value } = e.target;
    if (typeof index === "number") {
      const sections = [...formData.sections];
      sections[index][field] = value;
      setFormData({ ...formData, sections });
    } else {
      setFormData({ ...formData, [id]: value });
    }
  };

  const handleCropComplete = (croppedArea, croppedAreaPixels) => {
    // Handle the cropped area here if needed
    // console.log(croppedArea, croppedAreaPixels);
  };

  const onFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        console.log("File loaded: ", reader.result); // Log the loaded file
        setTempImageUrl(reader.result); // Set the temporary image URL
        setImageSrc(reader.result);
        setShowCropper(true);
      };
      setImageFile(file);
    }
  };

  const handleCropClose = async (croppedImageBlob) => {
    if (croppedImageBlob) {
      const storageRef = ref(getStorage(), `images/${imageFile.name}`);
      await uploadBytes(storageRef, croppedImageBlob);
      const downloadURL = await getDownloadURL(storageRef);
      setImageUrl(downloadURL);
      setFormData({ ...formData, imageUrl: downloadURL }); // Update formData with the new imageUrl
      setTempImageUrl(downloadURL); // Set temporary image URL with cropped image
    }
    setShowCropper(false);
  };

  const addSection = () => {
    setFormData((prevData) => ({
      id: uuidv4(),
      ...prevData,
      sections: [...prevData.sections, { id: uuidv4(), detailTitle: "", detailDescription: "" }]
    }));
  };

  const removeSection = (id) => {
    setFormData((prevData) => ({
      ...prevData,
      sections: prevData.sections.filter((section) => section.id !== id)
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.aboutmeTitle) newErrors.aboutmeTitle = "About Me Title is required.";
    if (!formData.description) newErrors.description = "Description is required.";
    formData.sections.forEach((section, index) => {
      if (!section.detailTitle) newErrors[`detailTitle${index}`] = "Detail Title is required.";
      if (!section.detailDescription)
        newErrors[`detailDescription${index}`] = "Detail Description is required.";
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true);

    try {
      await addDoc(collection(getFirestore(), "aboutMe"), {
        ...formData,
        imageUrl: imageUrl || "",
        timestamp: serverTimestamp() // Add the timestamp here
      });
      setSuccessMessage("Data submitted successfully!");
      setFormData({
        id: uuidv4(),
        imageUrl: "",
        aboutmeTitle: "",
        description: "",
        sections: [{ id: uuidv4(), detailTitle: "", detailDescription: "" }]
      });
      fetchData(); // Refresh the data after submission

      setTimeout(() => {
        setSuccessMessage("done re baba");
      }, 3000);
    } catch (error) {
      alert("Error submitting data: " + error.message);
    }

    setIsSubmitting(false);
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="myPhoto" className="mb-3">
          <Form.Label>My Photo</Form.Label>
          <Form.Control type="file" onChange={onFileChange} ref={inputFileRef} />
          <Form.Control.Feedback type="invalid">error</Form.Control.Feedback>
        </Form.Group>
        {showCropper && (
          <ImageCropModal
            show={showCropper}
            handleClose={handleCropClose}
            imageSrc={imageSrc}
            onCropComplete={handleCropComplete} // Pass the function here
          />
        )}
        {tempImageUrl && !imageUrl && (
          <div style={{ width: "120px" }}>
            <img src={tempImageUrl} alt="change" className="img-thumbnail" />
            {console.log("Rendering tempImageUrl: ", tempImageUrl)}
          </div>
        )}
        {imageUrl && !tempImageUrl && (
          <div style={{ width: "150px" }}>
            <img src={imageUrl} alt="Uploaded" className="img-thumbnail" />
          </div>
        )}

        <Form.Group className="mb-3" controlId="aboutmeTitle">
          <Form.Label>About Me Title</Form.Label>
          <Form.Control
            type="text"
            placeholder="About Me Title"
            value={formData.aboutmeTitle}
            onChange={handleChange}
            isInvalid={!!errors.aboutmeTitle}
          />
          <Form.Control.Feedback type="invalid">{errors.aboutmeTitle}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3" controlId="description">
          <Form.Label>Description</Form.Label>
          <Form.Control
            type="text"
            placeholder="Description"
            value={formData.description}
            onChange={handleChange}
            isInvalid={!!errors.description}
          />
          <Form.Control.Feedback type="invalid">{errors.description}</Form.Control.Feedback>
        </Form.Group>
        {formData.sections.map((section, index) => (
          <div className="row align-items-end" key={section.id}>
            <div className="col-auto flex-grow-1">
              <Form.Group controlId={`detailTitle${index}`}>
                <Form.Label>Detail Title</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Detail Title"
                  value={section.detailTitle}
                  onChange={(e) => handleChange(e, index, "detailTitle")}
                  isInvalid={!!errors[`detailTitle${index}`]}
                />
                <Form.Control.Feedback type="invalid">
                  {errors[`detailTitle${index}`]}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className="col-auto flex-grow-1">
              <Form.Group controlId={`detailDescription${index}`}>
                <Form.Label>Detail Description</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Detail Description"
                  value={section.detailDescription}
                  onChange={(e) => handleChange(e, index, "detailDescription")}
                  isInvalid={!!errors[`detailDescription${index}`]}
                />
                <Form.Control.Feedback type="invalid">
                  {errors[`detailDescription${index}`]}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className="col-auto gap-2 d-flex justify-content-between">
              <Button type="button" onClick={addSection}>
                <i className="fas fa-plus"></i>
              </Button>
              {formData.sections.length > 1 && (
                <Button type="button" variant="danger" onClick={() => removeSection(section.id)}>
                  <i className="fas fa-minus"></i>
                </Button>
              )}
            </div>
          </div>
        ))}

        <Button type="submit" disabled={isSubmitting}>
          Submit
        </Button>
      </Form>
      {successMessage && <div className="alert alert-success mt-3">{successMessage}</div>}
    </div>
  );
};

export default AboutMeForm;
