import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";

import { Element } from "react-scroll";

import Footer from "../../components/Footer";
import GetInTouch from "../../components/GetInTouch";
import Testimonials from "../../components/Testimonials";
import Services from "../../components/Services";
import Resume from "../../components/Resume";
import HeroSection from "../../components/HeroSection";
import HeroSlider from "../../components/util/HeroSlider";
import Portfolio from "../../components/Portfolio";
import States from "../../components/States";

function HomePage() {
  const navigate = useNavigate();
  const auth = getAuth();
  const user = auth.currentUser;

  useEffect(() => {
    if (!user) {
      navigate("/");
    }
  }, [user, navigate]);
  return (
    <>
      <Element className="section" name="section-1">
        <HeroSlider />
      </Element>
      <Element className="section" name="section-2">
        <HeroSection />
      </Element>
      <Resume />
      <Element className="section" name="section-services">
        <Services />
      </Element>
      <States />
      <Element className="section" name="section-portfolio">
        <Portfolio itemsShow={8} moreButton />
      </Element>
      <Element className="section" name="section-testimonials">
        <Testimonials />
      </Element>
      <Element className="section" name="section-contact">
        <GetInTouch />
      </Element>

      <Footer />
    </>
  );
}
export default HomePage;
