import { useState, useEffect } from "react";
import { getFirestore, collection, getDocs, query, orderBy, limit } from "firebase/firestore";
import Loader from "../Loader";

const Connect = () => {
  const [connectConfigeData, setConnectConfigeData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const db = getFirestore();
      const connectConfigeCollection = collection(db, "connectConfig");
      const q = query(connectConfigeCollection, orderBy("timestamp", "desc"), limit(1));
      const querySnapshot = await getDocs(q);
      // console.log(q);
      if (!querySnapshot.empty) {
        const latestDoc = querySnapshot.docs[0];
        const data = latestDoc.data();
        setConnectConfigeData(data);
      } else {
        console.log("No documents found!");
      }
    };

    fetchData();
  }, []);

  if (!connectConfigeData) {
    return <Loader />;
  }
  return (
    <div className="row">
      <div className="col-md-4">
        <div className="item mb-sm50">
          <span className="icon icon-chat"></span>
          <div className="cont-info">
            <h6>Skype</h6>
            <p>{connectConfigeData.skypeId}</p>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="item mb-sm50">
          <span className="icon icon-envelope"></span>
          <div className="cont-info">
            <h6>Email</h6>
            <p>{connectConfigeData.emailAddress}</p>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="item">
          <span className="icon icon-map-pin"></span>
          <div className="cont-info">
            <h6>Address</h6>
            <p>{connectConfigeData.address}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Connect;
