// src\components\Header\Header.jsx

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-scroll";
import { Navbar, Nav, Container } from "react-bootstrap";
import { signOut } from "firebase/auth";
import { NavLink } from "react-router-dom";

import { auth } from "../../firebase";
import logoImg from "../../assets/img/logo.png";

const Header = ({ currentUser }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isSticky, setIsSticky] = useState(false);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup function to remove event listener when component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleLinkClick = () => {
    setExpanded(false);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      alert("User logged out successfully!");
      navigate("/");
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <Navbar
      collapseOnSelect
      expanded={expanded}
      expand="lg"
      className={`navbar-top ${isSticky ? "nav-scroll" : ""}`}
    >
      <Container>
        <Navbar.Brand href="/" className="logo">
          <img src={logoImg} alt="Skyrites" />
        </Navbar.Brand>

        <Navbar.Toggle
          aria-controls="navbarSupportedContent"
          onClick={() => setExpanded(!expanded)}
        >
          <span className="icon-bar">
            <i className="fas fa-bars"></i>
          </span>
        </Navbar.Toggle>

        <Navbar.Collapse id="navbarSupportedContent">
          {location.pathname === "/" ? (
            <Nav className="ms-auto">
              <li className="nav-item">
                <Link
                  className="nav-link"
                  activeClass="active"
                  to="section-1"
                  spy
                  smooth={true}
                  duration={500}
                  onClick={handleLinkClick}
                >
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  activeClass="active"
                  to="section-2"
                  spy
                  smooth={true}
                  duration={500}
                  onClick={handleLinkClick}
                >
                  About
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  activeClass="active"
                  to="section-services"
                  spy
                  smooth={true}
                  duration={500}
                  onClick={handleLinkClick}
                >
                  Services
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  activeClass="active"
                  to="section-portfolio"
                  spy
                  smooth={true}
                  duration={500}
                  onClick={handleLinkClick}
                >
                  Portfolio
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  activeClass="active"
                  to="section-testimonials"
                  spy
                  smooth={true}
                  duration={500}
                  onClick={handleLinkClick}
                >
                  Testimonials
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  activeClass="active"
                  to="section-contact"
                  spy
                  smooth={true}
                  duration={500}
                  onClick={handleLinkClick}
                >
                  Contact
                </Link>
              </li>
              {currentUser ? (
                <>
                  <li className="nav-item">
                    <NavLink className="nav-link border cursor-pointer" to="/admin">
                      Dashboard
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <button className="nav-link border cursor-pointer" onClick={handleLogout}>
                      Logout <i className="fas fa-sign-out-alt"></i>
                    </button>
                  </li>
                </>
              ) : (
                <li className="nav-item">
                  <NavLink className="nav-link cursor-pointer" to="login">
                    Login <i className="fas fa-sign-out-alt"></i>
                  </NavLink>
                </li>
              )}
            </Nav>
          ) : (
            <Nav className="ms-auto">
              <li className="nav-item">
                <NavLink className="nav-link" to="/">
                  <i className="fa fa-home"> </i> Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/portfolio">
                  Portfolio
                </NavLink>
              </li>
              {currentUser ? (
                <>
                  <li className="nav-item">
                    <NavLink className="nav-link border cursor-pointer" to="/admin">
                      Dashboard
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <button className="nav-link border cursor-pointer" onClick={handleLogout}>
                      Logout <i className="fas fa-sign-out-alt"></i>
                    </button>
                  </li>
                </>
              ) : (
                <li className="nav-item">
                  <NavLink className="nav-link cursor-pointer" to="login">
                    Login <i className="fas fa-sign-out-alt"></i>
                  </NavLink>
                </li>
              )}
            </Nav>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
