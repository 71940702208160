import './Button.scss';

const Button = ({ buttonType = 'link', type, ariaLabel, dataFront, buttonLink, lg, revert, dark, ...props }) => {
    switch (buttonType) {
        case 'link':
            return (
                <div className={`cButton ${lg ? 'cButton-lg' : ''} ${revert ? 'cButton-bord' : ''} ${dark ? 'cButton-dark' : ''} `}>
                    <a aria-label={ariaLabel} data-front={dataFront} data-back="&#xf1d8;" href={buttonLink} {...props}></a>
                </div>
            );
        case 'button':
        default:
            return (
                <div className={`cButton ${lg ? 'cButton-lg' : ''} ${revert ? 'cButton-bord' : ''} ${dark ? 'cButton-dark' : ''} `}>
                    <button type={type} data-front={dataFront} data-back="&#xf35d;" {...props}></button>
                </div>
            );
    }
};

export default Button;
