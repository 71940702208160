import { NavLink } from "react-router-dom";

const AdminSidebar = () => {
  return (
    <nav
      id="sidebarMenu"
      className="col-md-3 col-lg-2 d-md-block sidebar collapse d-print-none shadow-lg"
    >
      <div className="position-sticky">
        <ul className="list-group list-group-flush">
          <li className="list-group-item">
            <NavLink className="nav-link" to="admin-Dashboard">
              <i className="fas fa-tachometer-alt"></i>
              <span className="ps-3">Dashboard</span>
            </NavLink>
          </li>
          <li className="list-group-item">
            <NavLink className="nav-link" to="admin-home">
              <i className="fas fa-edit"></i>
              <span className="ps-3">Homepage</span>
            </NavLink>
          </li>
          <li className="list-group-item">
            <NavLink className="nav-link" to="admin-contact-form">
              <i className="fas fa-edit"></i>
              <span className="ps-3">Contact Form</span>
            </NavLink>
          </li>

          <li className="list-group-item">
            <NavLink className="nav-link" to="admin-portfolio">
              <i className="far fa-images"></i>
              <span className="ps-3">Portfolio</span>
            </NavLink>
          </li>
          <hr />
          <li className="list-group-item">
            <NavLink className="nav-link" to="admin-setting">
              <i className="fa fa-cog" aria-hidden="true"></i> <span className="ps-3">Setting</span>
            </NavLink>
          </li>
        </ul>
      </div>
    </nav>
  );
};
export default AdminSidebar;
