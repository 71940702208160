// App.jsx
import { useEffect, useState } from "react";
import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import HomePage from "./pages/frontend/Home";
import RootLayout from "./pages/Root";
import ErrorPage from "./pages/Error";
import PortfolioPage from "./pages/frontend/PortfolioPage";
import PrivateRoute from "./components/PrivateRoute";
import Authentication from "./pages/auth/Authentication";
import Dashboard from "./pages/admin/Dashboard";
import AdminRootLayout from "./pages/admin/AdminRoot";

import "./App.scss";
import AdminHome from "./pages/admin/AdminHome";
import AdminPortfolio from "./pages/admin/AdminPortfolio";
import AdminSetting from "./pages/admin/AdminSetting";
import ForgotPassword from "./pages/auth/ForgotPassword";
import AdminContactForm from "./pages/admin/AdminContactForm";
import Loader from "./components/Loader";

function App() {
  const [currentUser, setCurrentUser] = useState(null);
  const [showSignUp, setShowSignUp] = useState(false);
  const [authChecked, setAuthChecked] = useState(false);
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setAuthChecked(true); // Authentication check is complete
    });

    return () => unsubscribe();
  }, [auth]);

  const handleToggleAuthPage = () => {
    setShowSignUp(!showSignUp);
  };

  if (!authChecked) {
    return <Loader />; // Show a loading indicator while checking auth state
  }

  const router = createBrowserRouter([
    {
      path: "/",
      element: <RootLayout />,
      errorElement: <ErrorPage />,
      children: [
        { path: "", element: <HomePage /> },
        { path: "forgot-password", element: <ForgotPassword /> },
        {
          path: "login",
          element: currentUser ? (
            <Navigate to="/" replace />
          ) : (
            <Authentication toggleAuthPage={handleToggleAuthPage} showSignUp={false} />
          )
        },
        {
          path: "signup",
          element: currentUser ? (
            <Navigate to="/" replace />
          ) : (
            <Authentication toggleAuthPage={handleToggleAuthPage} showSignUp={true} />
          )
        },
        { path: "portfolio", element: <PortfolioPage /> }
      ]
    },
    {
      path: "/admin",
      element: <AdminRootLayout />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "",
          element: currentUser ? (
            <Navigate to="/admin/admin-dashboard" replace />
          ) : (
            <Authentication toggleAuthPage={handleToggleAuthPage} showSignUp={showSignUp} />
          )
        },
        {
          path: "admin-dashboard",
          element: (
            <PrivateRoute currentUser={currentUser}>
              <Dashboard />
            </PrivateRoute>
          )
        },
        {
          path: "admin-home",
          element: (
            <PrivateRoute currentUser={currentUser}>
              <AdminHome />
            </PrivateRoute>
          )
        },
        {
          path: "admin-contact-form",
          element: (
            <PrivateRoute currentUser={currentUser}>
              <AdminContactForm />
            </PrivateRoute>
          )
        },
        {
          path: "admin-portfolio",
          element: (
            <PrivateRoute currentUser={currentUser}>
              <AdminPortfolio />
            </PrivateRoute>
          )
        },
        {
          path: "admin-setting",
          element: (
            <PrivateRoute currentUser={currentUser}>
              <AdminSetting />
            </PrivateRoute>
          )
        }
      ]
    }
  ]);

  return <RouterProvider router={router} />;
}

export default App;
