import Toast from 'react-bootstrap/Toast';
import { createPortal } from 'react-dom';

const CommonToast = ({ show, onClose, message, toastVariant }) => {
    return createPortal(
        <Toast bg={toastVariant} show={show} onClose={onClose} delay={3000} autohide style={{ position: 'fixed', right: '10px', bottom: '10px', zIndex: 99 }}>
            <Toast.Header>
                <strong className="me-auto">Notification</strong>
            </Toast.Header>
            <Toast.Body>{message}</Toast.Body>
        </Toast>,
        document.getElementById('modal')
    );
};

export default CommonToast;
