// ForgotPassword.jsx

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import "./Login.scss";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false); // Track form submission state
  const [toastVariant, setToastVariant] = useState("");

  const signinHandler = () => {
    navigate("/login");
  };

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Disable the button on form submit

    const auth = getAuth();
    try {
      await sendPasswordResetEmail(auth, email);
      setToastVariant("success");
      setMessage("Password reset email sent! Check your inbox.");
      setRedirect(true); // Set redirect to true after a successful password reset
    } catch (error) {
      setToastVariant("danger");
      if (error.code === "auth/user-not-found") {
        setMessage("User not found. Please check your email and try again.");
      } else {
        setMessage(error.message);
      }
      setIsSubmitting(false); // Re-enable the button if there's an error
    }
  };
  useEffect(() => {
    if (redirect) {
      const timer = setTimeout(() => {
        navigate("/");
      }, 400000); // 10 seconds

      return () => clearTimeout(timer); // Cleanup the timer on component unmount
    }
  }, [redirect, navigate]);

  return (
    <div className="auth-wrapper">
      <div className={`cont  s--signup `}>
        <div className="sub-cont">
          <div className="img">
            <div className="img__text m--in">
              <h2>One of us?</h2>
              <p>If you already has an account, just sign in. We've missed you!</p>
            </div>
            <div className="img__btn" onClick={signinHandler}>
              <span className="m--in">Sign In</span>
            </div>
          </div>
          <form className="form forgot-password" onSubmit={handlePasswordReset}>
            <h2>Time to feel like home,</h2>
            <label>
              <span>Email</span>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                required
              />
            </label>
            <button type="submit" className="submit" disabled={isSubmitting}>
              {isSubmitting ? "Submitting..." : "Reset Password"}
            </button>
            {message && <Alert variant={toastVariant}> {message} </Alert>}
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
