import { Link } from 'react-router-dom';
import 'yet-another-react-lightbox/styles.css';

const PortfolioItem = ({ src, alt, title, categories, url, clickEvent, idx }) => {
    return (
        <div className="masonry-item items design website">
            <div className="item-img">
                <img src={src} alt={alt} />
                <div className="item-img-overlay text-center valign">
                    <div className="overlay-info vertical-center full-width">
                        <h6>
                            {categories.map((category, index) => (
                                <span className="category" key={index}>
                                    {category}
                                </span>
                            ))}
                        </h6>
                        <h5>{title}</h5>
                    </div>
                    <button className="link " onClick={() => clickEvent(idx)}>
                        <i className="fas fa-eye"></i>
                    </button>
                    {url && (
                        <Link to={url} className="link" target="_blank">
                            <i className="fas fa-home"></i>
                        </Link>
                    )}
                </div>
            </div>
        </div>
    );
};
export default PortfolioItem;
