import { useState } from "react";
import { auth } from "../../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";

import "./Login.scss";
import { NavLink, useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      alert("User logged in successfully!");
      navigate("/");
    } catch (error) {
      alert(error.message);
    }
  };
  return (
    <form className="form sign-in" onSubmit={handleLogin}>
      <h2>Welcome back,</h2>
      <label>
        <span>Email</span>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          required
        />
      </label>
      <label>
        <span>Password</span>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          required
        />
      </label>
      <div className="text-center">
        <NavLink to="../forgot-password" className="forgot-pass">
          Forgot password?
        </NavLink>
      </div>
      <button type="submit" className="submit">
        Sign In
      </button>
    </form>
  );
};

export default Login;
