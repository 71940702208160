import { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { auth } from "../../firebase";
import { onAuthStateChanged } from "firebase/auth";

import AdminHeader from "../../components/Header/AdminHeader";
import AdminFooter from "../../components/Footer/AdminFooter";
import AdminSidebar from "./AdminSidebar";

function AdminRootLayout() {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });

    return () => unsubscribe();
  }, []);
  // Initialize theme from localStorage or default to "dark-theme"
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "dark-theme");

  useEffect(() => {
    document.body.className = theme; // Set the body class to the current theme
    localStorage.setItem("theme", theme); // Store the current theme in localStorage
  }, [theme]);

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "light-theme" ? "dark-theme" : "light-theme"));
  };
  return (
    <main className="admin-root">
      <AdminHeader currentUser={currentUser} />
      <div className="theme-switcher">
        <input
          id="switcher"
          type="checkbox"
          checked={theme === "light-theme"}
          onChange={toggleTheme}
        />
        <label htmlFor="switcher">Switch</label>
      </div>
      <main className="admin-block">
        <div className="container-fluid">
          <div className="row">
            <AdminSidebar />
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 pb-5 mb-5">
              <Outlet />
            </main>
          </div>
        </div>
      </main>
      <AdminFooter />
    </main>
  );
}
export default AdminRootLayout;
