const Loader = () => {
  return (
    <>
      <div className="loading">
        <div className="text-center middle">
          <div className="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Loader;
