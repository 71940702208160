// Authentication.js

import { useState } from "react";
import "./Login.scss";
import SignUp from "./SignUp";
import Login from "./Login";

const Authentication = ({ toggleAuthPage, showSignUp }) => {
  const [isSignup, setIsSignup] = useState(showSignUp);

  const toggleClass = () => {
    setIsSignup(!isSignup);
    toggleAuthPage();
  };

  return (
    <div className="auth-wrapper">
      <div className={`cont ${isSignup ? "s--signup" : ""}`}>
        <Login />
        <SignUp EventtoggleClass={toggleClass} />
      </div>
    </div>
  );
};

export default Authentication;
