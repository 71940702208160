import { NavLink } from "react-router-dom";

const AdminFooter = () => {
  return (
    <footer className="admin-footer fixed-bottom bottom-0 end-0">
      <p className="mb-0">
        <NavLink className="text-decoration-none" title="Developed by Skyrites" href="skyrites.com">
          &copy; skyrites
        </NavLink>
      </p>
    </footer>
  );
};

export default AdminFooter;
