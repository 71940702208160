import { useState, useEffect } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import PortfolioItem from "./util/PortfolioItem";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";

// import { portfolioItems } from "../../data/portfolioItemsData";

import Button from "./UI/Button";
import Heading from "./UI/Heading";
import BackHeader from "./Header/BackHeader";

const FilterButtons = ({ filter, setFilter, portfolioItems }) => {
  const categoriesFromItems = Array.from(
    new Set(
      portfolioItems.flatMap((item) => item.categories.map((category) => category.toLowerCase()))
    )
  );

  // Add 'All' to the beginning of the array
  const filters = ["all", ...categoriesFromItems];

  // console.log(filters);
  const formatLabel = (label) => {
    return label.replace(/-/g, " ").replace(/\b\w/g, (char) => char.toUpperCase());
  };

  return (
    <div className="filter-buttons w-100 filtering mb-5">
      <div className="d-flex flex-md-wrap w-100 justify-content-center">
        {filters.map((f) => (
          <button
            key={f}
            className={`filter-button ${filter === f ? "active" : ""}`}
            onClick={() => setFilter(f)}
          >
            {formatLabel(f)}
          </button>
        ))}
      </div>
    </div>
  );
};

const Portfolio = ({ itemsShow, moreButton = false, backHeader = false }) => {
  // State
  const [index, setIndex] = useState(-1);
  const [filter, setFilter] = useState("all");
  const [portfolioItems, setPortfolioItems] = useState([]);
  const [itemsToShow, setItemsToShow] = useState(); // default to 10 items

  useEffect(() => {
    setItemsToShow(itemsShow);
    const fetchPortfolioItems = async () => {
      const querySnapshot = await getDocs(collection(db, "portfolioItems"));
      const items = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      setPortfolioItems(items);
    };

    fetchPortfolioItems();
  }, [itemsShow]);

  const filteredItems =
    filter === "all"
      ? portfolioItems
      : portfolioItems.filter((item) =>
          item.categories.map((category) => category.toLowerCase()).includes(filter)
        );

  // Limit to the number of items to show based on screen size
  const limitedItems = filteredItems.slice(0, itemsToShow);

  return (
    <>
      <section className="portfolio section-padding">
        <div className="container">
          <div className="row">
            <div className="col-12">
              {backHeader && <BackHeader />}
              <Heading title="My Portfolio" />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <FilterButtons
                filter={filter}
                setFilter={setFilter}
                portfolioItems={portfolioItems}
              />
              <ResponsiveMasonry
                className="portfolio-block"
                columnsCountBreakPoints={{ 320: 1, 340: 2, 600: 3, 900: 4 }}
              >
                <Masonry>
                  {limitedItems.map((item, index) => (
                    <PortfolioItem
                      key={index}
                      idx={index}
                      src={item.src}
                      alt={item.alt}
                      title={item.title}
                      link={item.link}
                      categories={item.categories}
                      url={item.url}
                      clickEvent={(idx) => {
                        setIndex(idx);
                      }}
                    />
                  ))}
                </Masonry>
              </ResponsiveMasonry>
            </div>
          </div>
          {moreButton && (
            <div className="row">
              <div className="col-12 d-flex justify-content-center">
                <Button buttonType="link" buttonLink="portfolio" dataFront="View All" />
              </div>
            </div>
          )}
        </div>
      </section>
      <Lightbox index={index} slides={limitedItems} open={index >= 0} close={() => setIndex(-1)} />
    </>
  );
};

export default Portfolio;
