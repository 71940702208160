
import { useNavigate } from 'react-router-dom';

import Container from 'react-bootstrap/Container';

import { Button } from 'react-bootstrap';

const BackHeader = () => {
    const navigate = useNavigate();
    function cancelHandler() {
        navigate('/');
    }

    return (
        <nav>
            <Container>
                <Button variant="outline-light" onClick={cancelHandler}>
                    <span className="">
                        <i className="fas fa-arrow-left"></i>
                    </span>
                </Button>
               
            </Container>
        </nav>
    );
};

export default BackHeader;
