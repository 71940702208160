// src/firebase.js
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDcXgwWFI7xQw1BS7XV3sQ3FdypvbwdxIo",
  authDomain: "skyrites-91114.firebaseapp.com",
  projectId: "skyrites-91114",
  storageBucket: "skyrites-91114.appspot.com",
  messagingSenderId: "293964400681",
  appId: "1:293964400681:web:f3e78992fd9726d4d33438"
};

// Initialize Firestore
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const db = getFirestore(app);
export const auth = getAuth(app);

export { storage, db };
