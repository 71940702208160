import React, { useState } from "react";
import { Form, Button, Col, Row } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import { collection, addDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import ImageCropModal from "../../../components/ImageCropModal";
import { db, storage } from "../../../firebase";

const ServiceForm = () => {
  const [formData, setFormData] = useState({
    serviceTitle: "",
    serviceContent: "",
    serviceImg: null,
    iconName: "",
    buttonText: "",
    buttonLink: ""
  });

  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [tempImageUrl, setTempImageUrl] = useState("");
  const [imageSrc, setImageSrc] = useState(null);
  const [showCropper, setShowCropper] = useState(false);

  const [sections, setSections] = useState([
    {
      id: uuidv4(),
      title: "",
      content: "",
      buttonLink: "",
      buttonText: "",
      iconName: "",
      serviceImg: ""
    }
  ]);

  const handleSectionChange = (id, e) => {
    const { name, value, files } = e.target;
    setSections((prevSections) =>
      prevSections.map((section) =>
        section.id === id ? { ...section, [name]: files ? files[0] : value } : section
      )
    );
    if (name === "serviceImg") {
      onFileChange(e, id);
    }
  };

  const addSection = () => {
    setSections((prevSections) => [
      ...prevSections,
      {
        id: uuidv4(),
        title: "",
        content: "",
        buttonLink: "",
        buttonText: "",
        iconName: "",
        serviceImg: ""
      }
    ]);
  };

  const removeSection = (id) => {
    setSections((prevSections) => prevSections.filter((section) => section.id !== id));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const id = uuidv4();

    try {
      let imgURL = "";
      if (formData.serviceImg) {
        const storageRef = ref(storage, `images/${id}/${formData.serviceImg.name}`);
        await uploadBytes(storageRef, formData.serviceImg);
        imgURL = await getDownloadURL(storageRef);
      }

      const sectionsWithImageUrls = await Promise.all(
        sections.map(async (section) => {
          if (section.serviceImg && typeof section.serviceImg !== "string") {
            const sectionStorageRef = ref(storage, `images/${id}/${section.serviceImg.name}`);
            await uploadBytes(sectionStorageRef, section.serviceImg);
            const sectionImgURL = await getDownloadURL(sectionStorageRef);
            return { ...section, serviceImg: sectionImgURL };
          }
          return section;
        })
      );

      await addDoc(collection(db, "services"), {
        id,
        serviceTitle: formData.serviceTitle,
        serviceContent: formData.serviceContent,
        serviceImg: imgURL,
        iconName: formData.serviceImg ? "" : formData.iconName,
        buttonText: formData.buttonText,
        buttonLink: formData.buttonLink,
        sections: sectionsWithImageUrls
      });

      setSuccessMessage("Service added successfully!");
      setFormData({
        serviceTitle: "",
        serviceContent: "",
        serviceImg: null,
        iconName: "",
        buttonText: "",
        buttonLink: ""
      });
      setSections([
        {
          id: uuidv4(),
          title: "",
          content: "",
          buttonLink: "",
          buttonText: "",
          iconName: "",
          serviceImg: ""
        }
      ]);
      setImageFile(null);
      setImageUrl("");
      setTempImageUrl("");
      setErrors({});
    } catch (error) {
      console.error("Error adding document: ", error);
      setErrors({ submit: "Error adding service" });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCropComplete = (croppedArea, croppedAreaPixels) => {
    // Handle the cropped area here if needed
    // console.log(croppedArea, croppedAreaPixels);
  };

  const onFileChange = async (e, sectionId = null) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setTempImageUrl(reader.result); // Set the temporary image URL
        setImageSrc(reader.result);
        setShowCropper(true);
      };
      setImageFile(file);
      if (sectionId) {
        setSections((prevSections) =>
          prevSections.map((section) =>
            section.id === sectionId ? { ...section, serviceImg: file } : section
          )
        );
      }
    }
  };

  const handleCropClose = async (croppedImageBlob) => {
    if (croppedImageBlob) {
      const storageRef = ref(getStorage(), `images/${imageFile.name}`);
      await uploadBytes(storageRef, croppedImageBlob);
      const downloadURL = await getDownloadURL(storageRef);
      setImageUrl(downloadURL);
      setFormData((prevData) => ({ ...prevData, serviceImg: downloadURL })); // Update formData with the new imageUrl
      setTempImageUrl(downloadURL); // Set temporary image URL with cropped image
    }
    setShowCropper(false);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <h4>Add sections</h4>
      {sections.map((section, index) => (
        <div key={section.id} className="mb-3">
          <h5>Section {index + 1}</h5>

          <Form.Group as={Row} controlId={`sectionTitle-${section.id}`}>
            <Form.Label column sm="2">
              Section Title
            </Form.Label>
            <Col sm="10">
              <Form.Control
                type="text"
                name="title"
                value={section.title}
                onChange={(e) => handleSectionChange(section.id, e)}
                required
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId={`sectionContent-${section.id}`}>
            <Form.Label column sm="2">
              Section Content
            </Form.Label>
            <Col sm="10">
              <Form.Control
                as="textarea"
                name="content"
                value={section.content}
                onChange={(e) => handleSectionChange(section.id, e)}
                required
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId={`sectionIconName-${section.id}`}>
            <Form.Label column sm="2">
              Icon Name
            </Form.Label>
            <Col sm="10">
              <Form.Control
                type="text"
                name="iconName"
                value={section.iconName}
                onChange={(e) => handleSectionChange(section.id, e)}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId={`formServiceImg-${section.id}`}>
            <Form.Label column sm="2">
              Service Image
            </Form.Label>
            <Col sm="10">
              <Form.Control
                type="file"
                name="serviceImg"
                onChange={(e) => handleSectionChange(section.id, e)}
              />
            </Col>
          </Form.Group>

          {showCropper && (
            <ImageCropModal
              show={showCropper}
              handleClose={handleCropClose}
              imageSrc={imageSrc}
              onCropComplete={handleCropComplete} // Pass the function here
              aspect={1 / 1} // Set the desired aspect ratio here
              width={100}
            />
          )}

          {tempImageUrl && !imageUrl && (
            <div style={{ width: "120px" }}>
              <img src={tempImageUrl} alt="change" className="img-thumbnail" />
            </div>
          )}
          {imageUrl && !tempImageUrl && (
            <div style={{ width: "150px" }}>
              <img src={imageUrl} alt="Uploaded" className="img-thumbnail" />
            </div>
          )}

          <Form.Group as={Row} controlId={`sectionButtonText-${section.id}`}>
            <Form.Label column sm="2">
              Button Text
            </Form.Label>
            <Col sm="10">
              <Form.Control
                type="text"
                name="buttonText"
                value={section.buttonText}
                onChange={(e) => handleSectionChange(section.id, e)}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId={`sectionButtonLink-${section.id}`}>
            <Form.Label column sm="2">
              Button Link
            </Form.Label>
            <Col sm="10">
              <Form.Control
                type="text"
                name="buttonLink"
                value={section.buttonLink}
                onChange={(e) => handleSectionChange(section.id, e)}
              />
            </Col>
          </Form.Group>

          <Button variant="danger" onClick={() => removeSection(section.id)}>
            Remove Section
          </Button>
        </div>
      ))}

      <Button variant="primary" onClick={addSection}>
        Add Section
      </Button>

      <Button type="submit" className="mt-3" disabled={isSubmitting}>
        Submit
      </Button>

      {errors.submit && <p className="text-danger mt-3">{errors.submit}</p>}
      {successMessage && <p className="text-success mt-3">{successMessage}</p>}
    </Form>
  );
};

export default ServiceForm;
