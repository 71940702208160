// adminHeader.jsx

import { useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { Navbar, Container } from "react-bootstrap";
import { signOut } from "firebase/auth";

import { auth } from "../../firebase";

import logoImg from "../../assets/img/logo.png";
import "../../assets/scss/Admin.scss";

const AdminHeader = () => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      alert("User logged out successfully!");
      navigate("/");
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <Navbar className="navbar sticky-top flex-md-nowrap p-0 shadow d-print-none admin-header">
      <Container fluid className="px-0">
        <Navbar.Brand href="/admin" className="col-md-3 col-lg-2 me-0 p-2">
          <img src={logoImg} style={{ width: "118px", margin: "0 auto" }} alt="Skyrites" />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="sidebarMenu"
          className="navbar-toggler position-absolute d-md-none collapsed"
          onClick={() => setExpanded(!expanded)}
        >
          <span className="icon-bar">
            <i className="fas fa-bars"></i>
          </span>
        </Navbar.Toggle>
        <input
          className="form-control form-control-dark w-100"
          type="search"
          placeholder="Search"
          aria-label="Search"
        />
        <ul className="navbar-nav bg px-3">
          <li className="nav-item text-nowrap">
            <NavLink className="nav-link text-warning fw-bold" to="/">
              <i className="fas fa-home"></i> Home
            </NavLink>
          </li>
          <li className="nav-item text-nowrap">
            <NavLink className="nav-link text-warning fw-bold" onClick={handleLogout}>
              Logout <i className="fas fa-sign-out-alt"></i>
            </NavLink>
          </li>
        </ul>
      </Container>
    </Navbar>
  );
};

export default AdminHeader;
