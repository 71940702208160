import React, { Fragment, useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import { Row } from "react-bootstrap";
import Heading from "./UI/Heading";
import Button from "./UI/Button";
import Loader from "./Loader";

const ServiceList = () => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchServices = async () => {
      setLoading(true);
      try {
        const querySnapshot = await getDocs(collection(db, "services"));
        const servicesData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data()
        }));
        setServices(servicesData);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchServices();
  }, []);

  const getClassNames = (index) => {
    let classNames = "";
    if (index === 1 || index === 4) {
      classNames = "active bolt";
    }
    if (index === 6) {
      classNames = "active bolt mx-auto";
    }
    return classNames;
  };
  if (loading) return <Loader />;
  if (error) return <p>Error: {error}</p>;

  return (
    <section className="services section-padding">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <Heading title="My Services" />
          </div>
        </div>
        <div className="row">
          {services.map((service) => (
            <div key={service.id}>
              {service.sections && (
                <Row className="justify-content-center">
                  {service.sections.map((section, index) => (
                    <Fragment key={section.id}>
                      <div className={`col-lg-4 col-md-6 ${getClassNames(index)}`}>
                        <div className="item mb-md50">
                          {section.serviceImg ? (
                            <span className="icon">
                              <img
                                src={section.serviceImg}
                                alt={section.title}
                                style={{ width: "80px" }}
                              />
                            </span>
                          ) : (
                            <span className={`icon ${section.iconName || ""}`}></span>
                          )}

                          <h6>{section.title}</h6>
                          <p dangerouslySetInnerHTML={{ __html: section.content }}></p>

                          {section.buttonText && (
                            <div className="mt-4">
                              <Button
                                buttonType="link"
                                dataFront={section.buttonText}
                                buttonLink={section.buttonLink}
                                target="_blank"
                                ariaLabel={`Check Me ${section.buttonText}`}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      {(index + 1) % 3 === 0 && <div className="bord full-width"></div>}
                    </Fragment>
                  ))}
                </Row>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServiceList;

// import { Fragment } from 'react';
// import { serviceList } from '../data/serviceList';
// import MyServices from './util/MyServices';
// import Heading from './UI/Heading';

// export default function Services() {
//     return (
//         <section className="services section-padding">
//             <div className="container">
//                 <div className="row">
//                     <div className="col-12">
//                         <Heading title="My Services" />
//                     </div>
//                 </div>
//                 <div className="row">
//                     {serviceList.map((item) => (
//                         <Fragment key={item.id}>
//                             <div className={`col-lg-4 col-md-6 ${item.id === 2 || item.id === 5 ? 'active bolt' : ''}  ${item.id === 7 ? 'active bolt mx-auto' : ''}`}>
//                                 <MyServices {...item} />
//                             </div>
//                             {item.id % 3 === 0 && <div className="bord full-width"></div>}
//                         </Fragment>
//                     ))}
//                 </div>
//             </div>
//         </section>
//     );
// }
