import Experience from "./util/Experience";
import SkillProgress from "./util/SkillProgress";
import TechnicalSkills from "./util/TechnicalSkills.jsx";
import { collection, getDocs, getFirestore, limit, orderBy, query } from "firebase/firestore";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

export default function Resume() {
  const [formData, setFormData] = useState({
    id: uuidv4(),
    imageUrl: "",
    technicalSkill: {
      technicalTitle: "",
      sections: [{ id: uuidv4(), skillsTitle: "", skillsDescription: "" }]
    },
    experience: {
      techExperienceTitle: "",
      sections: [{ id: uuidv4(), experienceTitle: "", experienceDescription: "" }]
    },
    mySkills: {
      mySkillsTitle: "",
      sections: [{ id: uuidv4(), mySkillTitle: "", mySkillDescription: "" }]
    }
  });

  const [imageUrl, setImageUrl] = useState("");

  const fetchData = async () => {
    const db = getFirestore();
    const resumeCollection = collection(db, "resume");
    const q = query(resumeCollection, orderBy("timestamp", "desc"), limit(1));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const latestDoc = querySnapshot.docs[0];
      const data = latestDoc.data();

      setFormData({
        id: uuidv4(),
        imageUrl: data.imageUrl || "",
        technicalSkill: {
          technicalTitle: data.technicalSkill?.technicalTitle || "",
          sections: data.technicalSkill?.sections
            ? data.technicalSkill.sections.map((section) => ({
                ...section,
                id: uuidv4()
              }))
            : [{ id: uuidv4(), skillsTitle: "", skillsDescription: "" }]
        },
        experience: {
          techExperienceTitle: data.experience?.techExperienceTitle || "",
          sections: data.experience?.sections
            ? data.experience.sections.map((section) => ({
                ...section,
                id: uuidv4()
              }))
            : [{ id: uuidv4(), experienceTitle: "", experienceDescription: "" }]
        },
        mySkills: {
          mySkillsTitle: data.mySkills?.mySkillsTitle || "",
          sections: data.mySkills?.sections
            ? data.mySkills.sections.map((section) => ({
                ...section,
                id: uuidv4()
              }))
            : [{ id: uuidv4(), mySkillTitle: "", mySkillDescription: "" }]
        }
      });
      setImageUrl(data.imageUrl);
    } else {
      console.log("No documents found!");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <section
      className="resume bg-img"
      data-overlay-dark="8"
      style={{ backgroundImage: `url(${imageUrl})` }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-sm-6">
            <div className="item mb-md50">
              <span className="icon icon-pencil"></span>
              <br />
              <h5 className="title title-left">{formData.technicalSkill.technicalTitle}</h5>
              <ul className="ps-0">
                {formData.technicalSkill.sections.map((skill) => (
                  <li key={skill.id}>
                    <TechnicalSkills {...skill} />
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="item mb-md50">
              <span className="icon icon-briefcase"></span>
              <br />
              <h5 className="title title-left">{formData.experience.techExperienceTitle}</h5>
              <ul className="ps-0">
                {formData.experience.sections.map((item) => (
                  <li key={item.id}>
                    <Experience {...item} />
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="item skills">
              <span className="icon icon-layers"></span>
              <br />
              <h5 className="title title-left">My Skills {formData.mySkills.mySkillsTitle}</h5>
              {formData.mySkills.sections.map((section, index) => (
                <SkillProgress
                  key={section.id}
                  skillName={section.mySkillTitle}
                  dataValue={section.mySkillDescription}
                />
              ))}
              {/* <SkillProgress skillName="ReactJs" dataValue={90} />
              <SkillProgress skillName="VuetJs" dataValue={80} />
              <SkillProgress skillName="Angular 5+" dataValue={65} />
              <SkillProgress skillName="Tailwind CSS" dataValue={80} />
              <SkillProgress skillName=" UI Development" dataValue={90} />
              <SkillProgress skillName="Javascript" dataValue={70} />
              <SkillProgress skillName="Web Design" dataValue={70} />
              <SkillProgress skillName="Bootstrap 4/5" dataValue={95} /> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
