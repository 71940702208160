import EnquiryForm from "./Enquiry";
import Connect from "./util/Connect";

export default function GetInTouch() {
  return (
    <section className="contact section-padding bg-img" data-overlay-dark="8">
      <div className="container">
        <div className="row">
          {/* <!-- Section-Head --> */}
          <div className="section-head full-width mb-50">
            <h4 className="title">Get In Touch</h4>
          </div>

          <EnquiryForm />
        </div>
        <div className="info full-width">
          <Connect />
        </div>
      </div>
    </section>
  );
}
