import AdminHeading from "../../components/util/AdminHeading";
import PortfolioForm from "./forms/PortfolioForm";

const AdminPortfolio = () => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <AdminHeading title="Admin Portfolio" />
          </div>
        </div>
      </div>
      <PortfolioForm />
    </>
  );
};

export default AdminPortfolio;
