import { useState, useEffect } from "react";
import { getFirestore, collection, addDoc, getDocs, serverTimestamp } from "firebase/firestore";
import { Card, Form, Button, Container, Row, Col } from "react-bootstrap";
import AdminHeading from "../../components/util/AdminHeading";

const AdminContactForm = () => {
  const [formData, setFormData] = useState({
    serviceId: "",
    templateId: "",
    userId: "",
    email: "",
    message: ""
  });
  const [formConData, setFormConData] = useState({
    skypeId: "",
    emailAddress: "",
    address: ""
  });

  useEffect(() => {
    const fetchData = async () => {
      const db = getFirestore();
      const emailConfigCollection = collection(db, "emailConfig");
      const connectConfigCollection = collection(db, "connectConfig");

      const emailConfigSnapshot = await getDocs(emailConfigCollection);
      const connectConfigSnapshot = await getDocs(connectConfigCollection);

      if (!emailConfigSnapshot.empty) {
        const emailConfigData = emailConfigSnapshot.docs[0].data(); // Assuming there's only one document
        setFormData(emailConfigData);
      }

      if (!connectConfigSnapshot.empty) {
        const connectConfigData = connectConfigSnapshot.docs[0].data(); // Assuming there's only one document
        setFormConData(connectConfigData);
      }
    };

    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleConChange = (e) => {
    const { name, value } = e.target;
    setFormConData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const db = getFirestore();
    const emailConfigCollection = collection(db, "emailConfig");

    try {
      await addDoc(emailConfigCollection, {
        ...formData,
        timestamp: serverTimestamp() // Add the timestamp here
      });
      console.log("Email configuration saved successfully!");
    } catch (error) {
      console.error("Error saving email configuration: ", error);
    }
  };

  const handleConnectSubmit = async (e) => {
    e.preventDefault();

    const db = getFirestore();
    const connectConfigCollection = collection(db, "connectConfig");

    try {
      await addDoc(connectConfigCollection, {
        ...formConData,
        timestamp: serverTimestamp() // Add the timestamp here
      });
      console.log("Connect configuration saved successfully!");
    } catch (error) {
      console.error("Error saving Connect configuration: ", error);
    }
  };

  return (
    <>
      <AdminHeading title="Contact Form" />
      <Container>
        <Row className="mb-4">
          <Col>
            <Card>
              <Card.Header as="h5">Add MailJS configuration</Card.Header>
              <Card.Body>
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="formServiceId" className="mb-3">
                    <Form.Label>Service ID</Form.Label>
                    <Form.Control
                      type="text"
                      name="serviceId"
                      value={formData.serviceId}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formTemplateId" className="mb-3">
                    <Form.Label>Template ID</Form.Label>
                    <Form.Control
                      type="text"
                      name="templateId"
                      value={formData.templateId}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formUserId" className="mb-3">
                    <Form.Label>User ID</Form.Label>
                    <Form.Control
                      type="text"
                      name="userId"
                      value={formData.userId}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formEmail" className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formMessage" className="mb-3">
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                      as="textarea"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Button variant="primary" type="submit">
                    Submit
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <Card.Header as="h5">Add location and connection</Card.Header>
              <Card.Body>
                <Form onSubmit={handleConnectSubmit}>
                  <Form.Group controlId="formSkypeId" className="mb-3">
                    <Form.Label>Skype ID</Form.Label>
                    <Form.Control
                      type="text"
                      name="skypeId"
                      value={formConData.skypeId}
                      onChange={handleConChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formEmailAddress" className="mb-3">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                      type="text"
                      name="emailAddress"
                      value={formConData.emailAddress}
                      onChange={handleConChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formAddress" className="mb-3">
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      type="text"
                      name="address"
                      value={formConData.address}
                      onChange={handleConChange}
                      required
                    />
                  </Form.Group>
                  <Button variant="primary" type="submit">
                    Save
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AdminContactForm;
