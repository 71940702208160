import { useState, useEffect, useRef } from "react";
import {
  getFirestore,
  collection,
  getDocs,
  addDoc,
  serverTimestamp,
  query,
  orderBy,
  limit
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { Button, Card, Form } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import ImageCropModal from "../../../components/ImageCropModal";

const StatesForm = () => {
  const [formData, setFormData] = useState({
    id: uuidv4(),
    imageUrl: "",
    sections: [
      {
        id: uuidv4(),
        statesIconName: "",
        statesName: "",
        statesNameExtra: "",
        statesValuePercentage: false,
        statesValue: ""
      }
    ]
  });
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [tempImageUrl, setTempImageUrl] = useState(""); // Temporary image URL state
  const [imageSrc, setImageSrc] = useState(null);
  const [showCropper, setShowCropper] = useState(false);
  const inputFileRef = useRef(null);

  const fetchData = async () => {
    const db = getFirestore();
    const statesCollection = collection(db, "states");
    const q = query(statesCollection, orderBy("timestamp", "desc"), limit(1));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const latestDoc = querySnapshot.docs[0];
      const data = latestDoc.data();
      setFormData({
        ...data,
        id: uuidv4(),
        sections: data.sections.map((section) => ({ ...section, id: uuidv4() }))
      });
      setImageUrl(data.imageUrl); // Set the imageUrl separately
    } else {
      console.log("No documents found!");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleChange = (e, index, field) => {
    const { value, checked, type } = e.target;
    const newValue = type === "checkbox" ? checked : value;

    if (typeof index === "number") {
      const sections = [...formData.sections];
      sections[index][field] = newValue;
      setFormData({ ...formData, sections });
    } else {
      setFormData({ ...formData, [field]: newValue });
    }
  };

  const handleCropComplete = (croppedArea, croppedAreaPixels) => {
    // Handle the cropped area here if needed
    // console.log(croppedArea, croppedAreaPixels);
  };

  const onFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        console.log("File loaded: ", reader.result); // Log the loaded file
        setTempImageUrl(reader.result); // Set the temporary image URL
        setImageSrc(reader.result);
        setShowCropper(true);
      };
      setImageFile(file);
    }
  };

  const handleCropClose = async (croppedImageBlob) => {
    if (croppedImageBlob) {
      const storageRef = ref(getStorage(), `images/${imageFile.name}`);
      await uploadBytes(storageRef, croppedImageBlob);
      const downloadURL = await getDownloadURL(storageRef);
      setImageUrl(downloadURL);
      setFormData({ ...formData, imageUrl: downloadURL }); // Update formData with the new imageUrl
      setTempImageUrl(downloadURL); // Set temporary image URL with cropped image
    }
    setShowCropper(false);
  };

  const addSection = () => {
    setFormData((prevData) => ({
      ...prevData,
      sections: [
        ...prevData.sections,
        {
          id: uuidv4(),
          statesIconName: "",
          statesName: "",
          statesNameExtra: "",
          statesValue: "",
          statesValuePercentage: false
        }
      ]
    }));
  };

  const removeSection = (id) => {
    setFormData((prevData) => ({
      ...prevData,
      sections: prevData.sections.filter((section) => section.id !== id)
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    formData.sections.forEach((section, index) => {
      if (!section.statesIconName)
        newErrors[`statesIconName${index}`] = "States ICon Name is required.";
      if (!section.statesName) newErrors[`statesName${index}`] = "States Title is required.";
      if (section.statesValuePercentage === null || section.statesValuePercentage === undefined) {
        newErrors[`statesValuePercentage${index}`] = "States Value Percentage is required.";
      }
      if (!section.statesValue) newErrors[`statesValue${index}`] = "States value is required.";
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true);

    try {
      await addDoc(collection(getFirestore(), "states"), {
        ...formData,
        imageUrl: imageUrl || "",
        timestamp: serverTimestamp() // Add the timestamp here
      });
      setSuccessMessage("Data submitted successfully!");
      setFormData({
        id: uuidv4(),
        imageUrl: "",
        sections: [
          {
            id: uuidv4(),
            statesIconName: "",
            statesName: "",
            statesNameExtra: "",
            statesValue: "",
            statesValuePercentage: false
          }
        ]
      });
      fetchData(); // Refresh the data after submission

      setTimeout(() => {
        setSuccessMessage("");
      }, 3000);
    } catch (error) {
      alert("Error submitting data: " + error.message);
    }

    setIsSubmitting(false);
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="StatesPhoto" className="mb-3">
          <Form.Label>Background Image</Form.Label>
          <Form.Control type="file" onChange={onFileChange} ref={inputFileRef} />
          <Form.Control.Feedback type="invalid">error</Form.Control.Feedback>
        </Form.Group>
        {showCropper && (
          <ImageCropModal
            show={showCropper}
            handleClose={handleCropClose}
            imageSrc={imageSrc}
            onCropComplete={handleCropComplete} // Pass the function here
          />
        )}
        {tempImageUrl && !imageUrl && (
          <div className="mb-3" style={{ width: "120px" }}>
            <img src={tempImageUrl} alt="change" className="img-thumbnail" />
            {console.log("Rendering tempImageUrl: ", tempImageUrl)}
          </div>
        )}
        {imageUrl && !tempImageUrl && (
          <div className="mb-3" style={{ width: "150px" }}>
            <img src={imageUrl} alt="Uploaded" className="img-thumbnail" />
          </div>
        )}

        {formData.sections.map((section, index) => (
          <Card className="mb-4" border="success" key={section.id}>
            <Card.Header>
              <div className="col-auto gap-2 d-flex justify-content-between">
                <Button type="button" size="sm" onClick={addSection}>
                  <i className="fas fa-plus"></i>
                </Button>
                {formData.sections.length > 1 && (
                  <Button
                    type="button"
                    variant="danger"
                    size="sm"
                    onClick={() => removeSection(section.id)}
                  >
                    <i className="fas fa-minus"></i>
                  </Button>
                )}
              </div>
            </Card.Header>
            <Card.Body>
              <div className="row align-items-end">
                <div className="col-auto flex-grow-1">
                  <Form.Group controlId={`statesIconName${index}`}>
                    <Form.Label>States Icon Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="States Icon Name"
                      value={section.statesIconName}
                      onChange={(e) => handleChange(e, index, "statesIconName")}
                      isInvalid={!!errors[`statesIconName${index}`]}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors[`statesIconName${index}`]}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col-auto flex-grow-1">
                  <Form.Group controlId={`statesName${index}`}>
                    <Form.Label>States Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="States Name"
                      value={section.statesName}
                      onChange={(e) => handleChange(e, index, "statesName")}
                      isInvalid={!!errors[`statesName${index}`]}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors[`statesName${index}`]}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col-auto flex-shrink-0">
                  <Form.Group controlId={`statesValue${index}`}>
                    <Form.Label>States Value</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="States Value"
                      value={section.statesValue}
                      onChange={(e) => handleChange(e, index, "statesValue")}
                      isInvalid={!!errors[`statesValue${index}`]}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors[`statesValue${index}`]}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col-auto flex-grow-0">
                  <Form.Group controlId={`statesNameExtra${index}`}>
                    <Form.Label>Extra States Value</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Extra States Value"
                      value={section.statesNameExtra}
                      onChange={(e) => handleChange(e, index, "statesNameExtra")}
                      isInvalid={!!errors[`statesNameExtra${index}`]}
                    />
                  </Form.Group>
                </div>
                <div className="col-auto flex-flex-shrink-0">
                  <Form.Check
                    type="switch"
                    id={`statesValuePercentage${index}`}
                    label="% value"
                    checked={section.statesValuePercentage}
                    onChange={(e) => handleChange(e, index, "statesValuePercentage")}
                    isInvalid={!!errors[`statesValuePercentage${index}`]}
                  />
                </div>
              </div>
            </Card.Body>
          </Card>
        ))}

        <Button type="submit" disabled={isSubmitting}>
          Submit
        </Button>
      </Form>
      {successMessage && <div className="alert alert-success mt-3">{successMessage}</div>}
    </div>
  );
};

export default StatesForm;
