import { useState, useEffect, useRef } from "react";
import { Button, Form } from "react-bootstrap";
import {
  getFirestore,
  collection,
  getDocs,
  addDoc,
  serverTimestamp,
  query,
  orderBy,
  limit
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import ImageCropModal from "../../../components/ImageCropModal";

const ResumeForm = () => {
  const [formData, setFormData] = useState({
    id: uuidv4(),
    imageUrl: "",
    technicalSkill: {
      technicalTitle: "",
      sections: [{ id: uuidv4(), skillsTitle: "", skillsDescription: "" }]
    },
    experience: {
      techExperienceTitle: "",
      sections: [{ id: uuidv4(), experienceTitle: "", experienceDescription: "" }]
    },
    mySkills: {
      mySkillsTitle: "",
      sections: [{ id: uuidv4(), mySkillTitle: "", mySkillDescription: "" }]
    }
  });
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [tempImageUrl, setTempImageUrl] = useState("");
  const [imageSrc, setImageSrc] = useState(null);
  const [showCropper, setShowCropper] = useState(false);
  const inputFileRef = useRef(null);

  const fetchData = async () => {
    const db = getFirestore();
    const resumeCollection = collection(db, "resume");
    const q = query(resumeCollection, orderBy("timestamp", "desc"), limit(1));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const latestDoc = querySnapshot.docs[0];
      const data = latestDoc.data();

      setFormData({
        id: uuidv4(),
        imageUrl: data.imageUrl || "",
        technicalSkill: {
          technicalTitle: data.technicalSkill?.technicalTitle || "",
          sections: data.technicalSkill?.sections
            ? data.technicalSkill.sections.map((section) => ({
                ...section,
                id: uuidv4()
              }))
            : [{ id: uuidv4(), skillsTitle: "", skillsDescription: "" }]
        },
        experience: {
          techExperienceTitle: data.experience?.techExperienceTitle || "",
          sections: data.experience?.sections
            ? data.experience.sections.map((section) => ({
                ...section,
                id: uuidv4()
              }))
            : [{ id: uuidv4(), experienceTitle: "", experienceDescription: "" }]
        },
        mySkills: {
          mySkillsTitle: data.mySkills?.mySkillsTitle || "",
          sections: data.mySkills?.sections
            ? data.mySkills.sections.map((section) => ({
                ...section,
                id: uuidv4()
              }))
            : [{ id: uuidv4(), mySkillTitle: "", mySkillDescription: "" }]
        }
      });
      setImageUrl(data.imageUrl);
    } else {
      console.log("No documents found!");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleChange = (e, index, field, sectionType) => {
    const { value } = e.target;
    const updatedFormData = { ...formData };

    if (sectionType === "technicalSkill") {
      if (typeof index === "number") {
        updatedFormData.technicalSkill.sections[index][field] = value;
      } else {
        updatedFormData.technicalSkill[field] = value;
      }
    } else if (sectionType === "experience") {
      if (typeof index === "number") {
        updatedFormData.experience.sections[index][field] = value;
      } else {
        updatedFormData.experience[field] = value;
      }
    } else if (sectionType === "mySkills") {
      if (typeof index === "number") {
        updatedFormData.mySkills.sections[index][field] = value;
      } else {
        updatedFormData.mySkills[field] = value;
      }
    }

    setFormData(updatedFormData);
  };

  const handleCropComplete = (croppedArea, croppedAreaPixels) => {
    // Handle the cropped area here if needed
    // console.log(croppedArea, croppedAreaPixels);
  };

  const onFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setTempImageUrl(reader.result);
        setImageSrc(reader.result);
        setShowCropper(true);
      };
    }
  };

  const handleCropClose = async (croppedImageBlob) => {
    if (croppedImageBlob) {
      const storageRef = ref(getStorage(), `images/${uuidv4()}`);
      await uploadBytes(storageRef, croppedImageBlob);
      const downloadURL = await getDownloadURL(storageRef);
      setImageUrl(downloadURL);
      setFormData({ ...formData, imageUrl: downloadURL });
      setTempImageUrl("");
    }
    setShowCropper(false);
  };

  const addSection = (sectionType) => {
    setFormData((prevData) => {
      const updatedData = { ...prevData };
      if (sectionType === "technicalSkill") {
        updatedData.technicalSkill.sections.push({
          id: uuidv4(),
          skillsTitle: "",
          skillsDescription: ""
        });
      } else if (sectionType === "experience") {
        updatedData.experience.sections.push({
          id: uuidv4(),
          experienceTitle: "",
          experienceDescription: ""
        });
      } else if (sectionType === "mySkills") {
        updatedData.mySkills.sections.push({
          id: uuidv4(),
          mySkillTitle: "",
          mySkillDescription: ""
        });
      }
      return updatedData;
    });
  };

  const removeSection = (id, sectionType) => {
    setFormData((prevData) => {
      const updatedData = { ...prevData };
      if (sectionType === "technicalSkill") {
        updatedData.technicalSkill.sections = updatedData.technicalSkill.sections.filter(
          (section) => section.id !== id
        );
      } else if (sectionType === "experience") {
        updatedData.experience.sections = updatedData.experience.sections.filter(
          (section) => section.id !== id
        );
      } else if (sectionType === "mySkills") {
        updatedData.mySkills.sections = updatedData.mySkills.sections.filter(
          (section) => section.id !== id
        );
      }
      return updatedData;
    });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.technicalSkill.technicalTitle)
      newErrors.technicalTitle = "Skills Title is required.";
    formData.technicalSkill.sections.forEach((section, index) => {
      if (!section.skillsTitle) newErrors[`skillsTitle${index}`] = "Skills Title is required.";
      if (!section.skillsDescription)
        newErrors[`skillsDescription${index}`] = "Skills Description is required.";
    });
    //  Experience
    if (!formData.experience.techExperienceTitle)
      newErrors.techExperienceTitle = "Experience Title is required.";
    formData.experience.sections.forEach((section, index) => {
      if (!section.experienceTitle)
        newErrors[`experienceTitle${index}`] = "Experience Title is required.";
      if (!section.experienceDescription)
        newErrors[`experienceDescription${index}`] = "Experience Description is required.";
    });
    //  mySkills
    if (!formData.mySkills.mySkillsTitle) newErrors.mySkillsTitle = "Title is required.";
    formData.mySkills.sections.forEach((section, index) => {
      if (!section.mySkillTitle) newErrors[`mySkillTitle${index}`] = "Skill Title is required.";
      if (!section.mySkillDescription)
        newErrors[`mySkillDescription${index}`] = "Skill Description is required.";
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true);

    try {
      await addDoc(collection(getFirestore(), "resume"), {
        ...formData,
        timestamp: serverTimestamp()
      });
      setSuccessMessage("Data submitted successfully!");
      setFormData({
        id: uuidv4(),
        imageUrl: "",
        technicalSkill: {
          technicalTitle: "",
          sections: [{ id: uuidv4(), skillsTitle: "", skillsDescription: "" }]
        },
        experience: {
          techExperienceTitle: "",
          sections: [{ id: uuidv4(), experienceTitle: "", experienceDescription: "" }]
        },
        mySkills: {
          mySkillsTitle: "",
          sections: [{ id: uuidv4(), mySkillTitle: "", mySkillDescription: "" }]
        }
      });
      fetchData();

      setTimeout(() => {
        setSuccessMessage("");
      }, 3000);
    } catch (error) {
      alert("Error submitting data: " + error.message);
    }

    setIsSubmitting(false);
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="resumePhoto" className="mb-3">
          <Form.Label>Section Background Image</Form.Label>
          <Form.Control type="file" onChange={onFileChange} ref={inputFileRef} />
          <Form.Control.Feedback type="invalid">error</Form.Control.Feedback>
        </Form.Group>
        {showCropper && (
          <ImageCropModal
            show={showCropper}
            handleClose={handleCropClose}
            imageSrc={imageSrc}
            onCropComplete={handleCropComplete}
          />
        )}
        {tempImageUrl && !imageUrl && (
          <div style={{ width: "120px" }}>
            <img src={tempImageUrl} alt="change" className="img-thumbnail" />
          </div>
        )}
        {imageUrl && !tempImageUrl && (
          <div style={{ width: "150px" }}>
            <img src={imageUrl} alt="Uploaded" className="img-thumbnail" />
          </div>
        )}
        {/* /Technical sKill   */}
        <Form.Group className="mb-3" controlId="technicalTitle">
          <Form.Label>Technical Skills</Form.Label>
          <Form.Control
            type="text"
            placeholder="Technical Skills"
            value={formData.technicalSkill.technicalTitle}
            onChange={(e) => handleChange(e, null, "technicalTitle", "technicalSkill")}
            isInvalid={!!errors.technicalTitle}
          />
          <Form.Control.Feedback type="invalid">{errors.technicalTitle}</Form.Control.Feedback>
        </Form.Group>

        {formData.technicalSkill.sections.map((section, index) => (
          <div className="row align-items-end" key={section.id}>
            <div className="col-auto flex-grow-1">
              <Form.Group controlId={`skillsTitle${index}`}>
                <Form.Label>Skills Title</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Skills Title"
                  value={section.skillsTitle}
                  onChange={(e) => handleChange(e, index, "skillsTitle", "technicalSkill")}
                  isInvalid={!!errors[`skillsTitle${index}`]}
                />
                <Form.Control.Feedback type="invalid">
                  {errors[`skillsTitle${index}`]}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className="col-auto flex-grow-1">
              <Form.Group controlId={`skillsDescription${index}`}>
                <Form.Label>Skills Description</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Skills Description"
                  value={section.skillsDescription}
                  onChange={(e) => handleChange(e, index, "skillsDescription", "technicalSkill")}
                  isInvalid={!!errors[`skillsDescription${index}`]}
                />
                <Form.Control.Feedback type="invalid">
                  {errors[`skillsDescription${index}`]}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className="col-auto gap-2 d-flex justify-content-between">
              <Button type="button" onClick={() => addSection("technicalSkill")}>
                <i className="fas fa-plus"></i>
              </Button>
              {formData.technicalSkill.sections.length > 1 && (
                <Button
                  type="button"
                  variant="danger"
                  onClick={() => removeSection(section.id, "technicalSkill")}
                >
                  <i className="fas fa-minus"></i>
                </Button>
              )}
            </div>
          </div>
        ))}
        <hr />
        {/* /Technical sKill END   */}
        {/* experience START */}
        <Form.Group className="mb-3" controlId="techExperienceTitle">
          <Form.Label>Experience</Form.Label>
          <Form.Control
            type="text"
            placeholder="Experience"
            value={formData.experience.techExperienceTitle}
            onChange={(e) => handleChange(e, null, "techExperienceTitle", "experience")}
            isInvalid={!!errors.techExperienceTitle}
          />
          <Form.Control.Feedback type="invalid">{errors.techExperienceTitle}</Form.Control.Feedback>
        </Form.Group>

        {formData.experience.sections.map((section, index) => (
          <div className="row align-items-end" key={section.id}>
            <div className="col-auto flex-grow-1">
              <Form.Group controlId={`experienceTitle${index}`}>
                <Form.Label>Experience Title</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Experience Title"
                  value={section.experienceTitle}
                  onChange={(e) => handleChange(e, index, "experienceTitle", "experience")}
                  isInvalid={!!errors[`experienceTitle${index}`]}
                />
                <Form.Control.Feedback type="invalid">
                  {errors[`experienceTitle${index}`]}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className="col-auto flex-grow-1">
              <Form.Group controlId={`experienceDescription${index}`}>
                <Form.Label>Experience Description</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Experience Description"
                  value={section.experienceDescription}
                  onChange={(e) => handleChange(e, index, "experienceDescription", "experience")}
                  isInvalid={!!errors[`experienceDescription${index}`]}
                />
                <Form.Control.Feedback type="invalid">
                  {errors[`experienceDescription${index}`]}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className="col-auto gap-2 d-flex justify-content-between">
              <Button type="button" onClick={() => addSection("experience")}>
                <i className="fas fa-plus"></i>
              </Button>
              {formData.experience.sections.length > 1 && (
                <Button
                  type="button"
                  variant="danger"
                  onClick={() => removeSection(section.id, "experience")}
                >
                  <i className="fas fa-minus"></i>
                </Button>
              )}
            </div>
          </div>
        ))}

        {/* /EXPERIENCE END  */}

        {/* mySkills START */}
        <Form.Group className="mb-3" controlId="mySkillsTitle">
          <Form.Label>Skill</Form.Label>
          <Form.Control
            type="text"
            placeholder="Skill"
            value={formData.mySkills.mySkillsTitle}
            onChange={(e) => handleChange(e, null, "mySkillsTitle", "mySkills")}
            isInvalid={!!errors.mySkillsTitle}
          />
          <Form.Control.Feedback type="invalid">{errors.mySkillsTitle}</Form.Control.Feedback>
        </Form.Group>

        {formData.mySkills.sections.map((section, index) => (
          <div className="row align-items-end" key={section.id}>
            <div className="col-auto flex-grow-1">
              <Form.Group controlId={`mySkillTitle${index}`}>
                <Form.Label>Skill Title</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Skill Title"
                  value={section.mySkillTitle}
                  onChange={(e) => handleChange(e, index, "mySkillTitle", "mySkills")}
                  isInvalid={!!errors[`mySkillTitle${index}`]}
                />
                <Form.Control.Feedback type="invalid">
                  {errors[`mySkillTitle${index}`]}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className="col-auto flex-grow-1">
              <Form.Group controlId={`mySkillDescription${index}`}>
                <Form.Label>Rating</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Rating out of 100"
                  value={section.mySkillDescription}
                  onChange={(e) => handleChange(e, index, "mySkillDescription", "mySkills")}
                  isInvalid={!!errors[`mySkillDescription${index}`]}
                />
                <Form.Control.Feedback type="invalid">
                  {errors[`mySkillDescription${index}`]}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className="col-auto gap-2 d-flex justify-content-between">
              <Button type="button" onClick={() => addSection("mySkills")}>
                <i className="fas fa-plus"></i>
              </Button>
              {formData.mySkills.sections.length > 1 && (
                <Button
                  type="button"
                  variant="danger"
                  onClick={() => removeSection(section.id, "mySkills")}
                >
                  <i className="fas fa-minus"></i>
                </Button>
              )}
            </div>
          </div>
        ))}

        {/* /mySkill END  */}

        <Button type="submit" disabled={isSubmitting}>
          Submit
        </Button>
      </Form>
      {successMessage && <div className="alert alert-success mt-3">{successMessage}</div>}
    </div>
  );
};

export default ResumeForm;
