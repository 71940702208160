import { useState, useEffect } from "react";
import { Link } from "react-scroll";

import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase"; // Adjust the import based on your project structure

import "./HeroSlider.scss";

const HeroSlider = () => {
  const [slides, setSlides] = useState([]);

  useEffect(() => {
    const fetchSlides = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "heroSlider"));
        const slidesData = querySnapshot.docs.map((doc) => doc.data());
        setSlides(slidesData);
      } catch (error) {
        console.error("Error fetching slides: ", error);
      }
    };

    fetchSlides();
  }, []);

  return (
    <>
      {slides.length > 0 && (
        <header
          className="header valign bg-img"
          data-overlay-dark="5"
          style={{ backgroundImage: `url(${slides[0]?.backgroundImage})` }}
          data-stellar-background-ratio="0.5"
        >
          <div className="container">
            <div className="row">
              <div className="vertical-center full-width text-center caption">
                <h2>I Love To Design</h2>
                <h1 className="cd-headline clip">
                  <span className="blc">I Am </span>{" "}
                  <span className="cd-words-wrapper">
                    {/* <b className="is-visible">Skyrites</b>
                    <b>a Developer</b>
                    <b>a UI Designer</b>
                    <b>Pravin Zade</b> */}
                    <b className="is-visible">{slides[0].firstTitle}</b>
                    <b>{slides[0].secondTitle}</b>
                    <b>{slides[0].thirdTitle}</b>
                    <b>{slides[0].fourthTitle}</b>
                  </span>
                </h1>
              </div>
            </div>
          </div>

          <div className="arrow">
            <Link to="section-2">
              <i className="fas fa-chevron-down"></i>
            </Link>
          </div>
        </header>
      )}
    </>
  );
};

export default HeroSlider;
