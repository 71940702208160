import PropTypes from "prop-types";

const AdminHeading = ({ title, level = 3 }) => {
  const HeadingTag = `h${level}`;

  return (
    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <HeadingTag>{title}</HeadingTag>
    </div>
  );
};

AdminHeading.propTypes = {
  title: PropTypes.string.isRequired,
  level: PropTypes.number
};

export default AdminHeading;
